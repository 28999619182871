import React, { useEffect, useState } from "react";
import Aos from "aos";

export default function Webdevelopment() {
  const [visibleCards, setVisibleCards] = useState(9);
  const handleShowMore = () => {
    setVisibleCards(projectCard.length);
  };
  const handleNavigation = (url) => {
    window.location.href = url;
  };
  const projectCard = [
    {
      projectname: "USP",
      projectimg: (
        <img
          className="w-full h-full object-cover group-hover:scale-110"
          src="web-development/1.png"
          alt=""
        />
      ),
    },
    {
      projectname: "PVA Deals",
      projectimg: (
        <img
          className="w-full h-full object-cover group-hover:scale-110"
          src="web-development/2.png"
          alt=""
        />
      ),
    },
    {
      projectname: "Volume Network",
      projectimg: (
        <img
          className="w-full h-full object-cover group-hover:scale-110"
          src="web-development/3.png"
          alt=""
        />
      ),
    },
    {
      projectname: "Geometra",
      projectimg: (
        <img
          className="w-full h-full object-cover group-hover:scale-110"
          src="web-development/4.png"
          alt=""
        />
      ),
    },
    {
      projectname: "Data Poem",
      projectimg: (
        <img
          className="w-full h-full object-cover group-hover:scale-110"
          src="web-development/5.png"
          alt=""
        />
      ),
    },

    {
      projectname: "Edup",
      projectimg: (
        <img
          className="w-full h-full object-cover group-hover:scale-110"
          src="web-development/6.png"
          alt=""
        />
      ),
    },
    {
      projectname: "Musika Lessons",
      projectimg: (
        <img
          className="w-full h-full object-cover group-hover:scale-110"
          src="web-development/7.png"
          alt=""
        />
      ),
    },
    {
      projectname: "Woptics",
      projectimg: (
        <img
          className="w-full h-full object-cover group-hover:scale-110"
          src="web-development/8.png"
          alt=""
        />
      ),
    },
    {
      projectname: "My Songs",
      projectimg: (
        <img
          className="w-full h-full object-cover group-hover:scale-110"
          src="web-development/9.png"
          alt=""
        />
      ),
    },
    {
      projectname: "Taskick",
      projectimg: (
        <img
          className="w-full h-full object-cover group-hover:scale-110"
          src="web-development/10.png"
          alt=""
        />
      ),
    },
    {
      projectname: "ilerno",
      projectimg: (
        <img
          className="w-full h-full object-cover group-hover:scale-110"
          src="web-development/11.png"
          alt=""
        />
      ),
    },
    {
      projectname: "Cult Booking",
      projectimg: (
        <img
          className="w-full h-full object-cover group-hover:scale-110"
          src="web-development/12.png"
          alt=""
        />
      ),
    },
    {
      projectname: "Blooprinted",
      projectimg: (
        <img
          className="w-full h-full object-cover group-hover:scale-110"
          src="web-development/13.png"
          alt=""
        />
      ),
    },
    {
      projectname: "Octopod",
      projectimg: (
        <img
          className="w-full h-full object-cover group-hover:scale-110"
          src="web-development/14.png"
          alt=""
        />
      ),
    },
    {
      projectname: "Strategy Exe",
      projectimg: (
        <img
          className="w-full h-full object-cover group-hover:scale-110"
          src="web-development/15.png"
          alt=""
        />
      ),
    },
    {
      projectname: "Creditlinks",
      projectimg: (
        <img
          className="w-full h-full object-cover group-hover:scale-110"
          src="web-development/16.png"
          alt=""
        />
      ),
    },
    {
      projectname: "Golden Suisse",
      projectimg: (
        <img
          className="w-full h-full object-cover group-hover:scale-110"
          src="web-development/17.png"
          alt=""
        />
      ),
    },
    {
      projectname: "Circuit Booking",
      projectimg: (
        <img
          className="w-full h-full object-cover group-hover:scale-110"
          src="web-development/18.png"
          alt=""
        />
      ),
    },
  ];

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div className="container mx-auto px-3">
      <div className="py-5">
        <a href="/" className="block">
          <img src="main-logo.png" alt="" className="w-64 max-sm:w-40" />
        </a>
      </div>

      <div className="max-w-[90%] mx-auto text-center">
        <h2 className="text-5xl max-sm:text-3xl font-semibold leading-tight text-center">
          We are a renowned{" "}
          <span className="bg-gradient-to-t from-[#000B6A] to-[#0014CB] text-transparent bg-clip-text">
            Web Development Company
          </span>{" "}
          based in India, dedicated to crafting websites that elevate your
          business.
        </h2>
      </div>

      <section className="py-20">
        <div className="container mx-auto">
          <div className="flex items-center justify-evenly max-sm:flex-wrap max-sm:gap-5">
            <div
              data-aos="zoom-in"
              data-aos-duration="1000"
              className="text-center w-1/3 max-sm:w-48"
            >
              <div className="bg-gradient-to-t text-5xl font-bold from-[#000B6A] to-[#0014CB] text-transparent bg-clip-text mb-3">
                12+
              </div>
              <span className="text-xl font-medium text-center leading-3 tracking-wide">
                Years of <br /> Experience
              </span>
            </div>
            <div
              data-aos="zoom-in"
              data-aos-duration="1000"
              className="text-center border-x border-x-black w-1/3 max-sm:w-48 max-sm:border-none"
            >
              <div className="bg-gradient-to-t text-5xl font-bold from-[#000B6A] to-[#0014CB] text-transparent bg-clip-text mb-3">
                600+
              </div>
              <span className="text-xl font-medium text-center leading-3 tracking-wide">
                Successful <br /> Projects Delivered
              </span>
            </div>
            <div
              data-aos="zoom-in"
              data-aos-duration="1000"
              className="text-center w-1/3 max-sm:w-48"
            >
              <div className="bg-gradient-to-t text-5xl font-bold from-[#000B6A] to-[#0014CB] text-transparent bg-clip-text mb-3">
                75+
              </div>
              <span className="text-xl font-medium text-center leading-3 tracking-wide">
                Our Certified
                <br /> Team
              </span>
            </div>
          </div>
        </div>
      </section>

      <section>
        <h3 className="text-center text-3xl font-semibold tracking-wide">
          A Showcase Of Our RecentTech Projects
        </h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 max-lg:gap-5 gap-10 max-sm:gap-5 mt-10 mb-16">
          {projectCard.slice(0, visibleCards).map((data, index) => {
            return (
              <div
                key={index}
                data-aos="zoom-in"
                className="p-3 max-h-[380px] h-[350px] max-sm:h-[300px] shadow-[0_0_30px_-5px_rgba(0,0,0,0.2)] rounded-2xl hover:scale-[0.950] cursor-pointer"
              >
                <div
                  onClick={() =>
                    handleNavigation(`/projectdetails/${data.projectname}`)
                  }
                  className="group relative h-full overflow-hidden rounded-2xl"
                >
                  {data.projectimg}
                  <div className="group h-full w-full bg-[#0505059e] grid place-items-center absolute top-0 left-0 rounded-2xl">
                    <div className="group text-center flex flex-col items-center justify-center gap-3 p-2">
                      <h4 className="max-sm:text-xl text-2xl max-sm:font-medium font-semibold text-white opacity-80 group-hover:opacity-100">
                        {data.projectname}
                      </h4>
                      <div className="h-0 w-0 overflow-hidden group-hover:h-10 group-hover:w-10">
                        <button className="border border-white h-full w-full grid place-items-center">
                          <svg
                            width="15"
                            height="12"
                            viewBox="0 0 15 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.85712 1L13.8571 6L8.85712 11"
                              stroke="#fff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M13.8571 6L1 6"
                              stroke="#fff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {visibleCards < projectCard.length && (
          <button
            className="border flex w-48 justify-center items-center gap-3 border-black py-3 px-5 text-base font-medium mx-auto hover:translate-x-2"
            onClick={handleShowMore}
          >
            Show More
          </button>
        )}
      </section>
    </div>
  );
}
