import React from "react";

export default function Applogoslider() {
  const appTechnologies = [
    {
      technologyImage: (
        <img
          className="w-24 h-24 object-contain"
          src="/mobileapp/technology-logos/Android.png"
          alt=""
        />
      ),
      technologyTitle: "Android",
    },
    {
      technologyImage: (
        <img
          className="w-24 h-24 object-contain"
          src="/mobileapp/technology-logos/flutter.png"
          alt=""
        />
      ),
      technologyTitle: "Flutter",
    },
    {
      technologyImage: (
        <img
          className="w-24 h-24 object-contain"
          src="/mobileapp/technology-logos/ios.png"
          alt=""
        />
      ),
      technologyTitle: "iOS",
    },
    {
      technologyImage: (
        <img
          className="w-24 h-24 object-contain"
          src="/mobileapp/technology-logos/Ionic.png"
          alt=""
        />
      ),
      technologyTitle: "Ionic",
    },
    {
      technologyImage: (
        <img
          className="w-24 h-24 object-contain"
          src="/mobileapp/technology-logos/Java.png"
          alt=""
        />
      ),
      technologyTitle: "Java",
    },
    {
      technologyImage: (
        <img
          className="w-24 h-24 object-contain"
          src="/mobileapp/technology-logos/Kotlin.png"
          alt=""
        />
      ),
      technologyTitle: "Kotlin",
    },
    {
      technologyImage: (
        <img
          className="w-24 h-24 object-contain"
          src="/mobileapp/technology-logos/NativeScript.png"
          alt=""
        />
      ),
      technologyTitle: "NativeScript",
    },
    {
      technologyImage: (
        <img
          className="w-24 h-24 object-contain"
          src="/mobileapp/technology-logos/React Native.png"
          alt=""
        />
      ),
      technologyTitle: "React Native",
    },
    {
      technologyImage: (
        <img
          className="w-24 h-24 object-contain"
          src="/mobileapp/technology-logos/Swift.png"
          alt=""
        />
      ),
      technologyTitle: "Swift Playgrounds",
    },
    {
      technologyImage: (
        <img
          className="w-24 h-24 object-contain"
          src="/mobileapp/technology-logos/Xamarin.png"
          alt=""
        />
      ),
      technologyTitle: "Xamarin",
    },
  ];

  return (
    <>
      <section className="overflow-hidden py-14">
        <div className="container mx-auto px-3">
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5 max-lg:gap-5 gap-5 items-baseline">
            {appTechnologies.map((data, i) => {
              return (
                <>
                  <div
                    data-aos="zoom-in"
                    className="logo-sm-card h-24 flex cursor-pointer flex-col justify-center items-center text-center"
                    key={i}
                  >
                    <div className="content">
                      <div className="front">{data.technologyImage}</div>
                      <div className="back">
                        <h5 className="text-xl font-medium">
                          {data.technologyTitle}
                        </h5>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}
