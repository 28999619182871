import React from "react";

export default function Weblogoslider() {
  const webTechnologies = [
    {
      technologyImage: (
        <img
          className="w-24 h-24 object-contain"
          src="/web-development/technology-logos/HTML.png"
          alt=""
        />
      ),
      technologyTitle: "HTML",
    },
    {
      technologyImage: (
        <img
          className="w-24 h-24 object-contain"
          src="/web-development/technology-logos/CSS.png"
          alt=""
        />
      ),
      technologyTitle: "CSS",
    },
    {
      technologyImage: (
        <img
          className="w-24 h-24 object-contain"
          src="/web-development/technology-logos/Javascript.png"
          alt=""
        />
      ),
      technologyTitle: "JavaScript",
    },
    {
      technologyImage: (
        <img
          className="w-24 h-24 object-contain"
          src="/web-development/technology-logos/React JS.png"
          alt=""
        />
      ),
      technologyTitle: "React",
    },
    {
      technologyImage: (
        <img
          className="w-24 h-24 object-contain"
          src="/web-development/technology-logos/Vuejs.png"
          alt=""
        />
      ),
      technologyTitle: "Vue.js",
    },
    {
      technologyImage: (
        <img
          className="w-24 h-24 object-contain"
          src="/web-development/technology-logos/AngularJS.png"
          alt=""
        />
      ),
      technologyTitle: "AngularJS",
    },
    {
      technologyImage: (
        <img
          className="w-24 h-24 object-contain"
          src="/web-development/technology-logos/Django.png"
          alt=""
        />
      ),
      technologyTitle: "Django",
    },
    {
      technologyImage: (
        <img
          className="w-24 h-24 object-contain"
          src="/web-development/technology-logos/Express JS.png"
          alt=""
        />
      ),
      technologyTitle: "Express.js",
    },
    {
      technologyImage: (
        <img
          className="w-24 h-24 object-contain"
          src="/web-development/technology-logos/Golang.png"
          alt=""
        />
      ),
      technologyTitle: "Go",
    },
    {
      technologyImage: (
        <img
          className="w-24 h-24 object-contain"
          src="/web-development/technology-logos/Laravel.png"
          alt=""
        />
      ),
      technologyTitle: "Laravel",
    },
    {
      technologyImage: (
        <img
          className="w-24 h-24 object-contain"
          src="/web-development/technology-logos/meteor js.png"
          alt=""
        />
      ),
      technologyTitle: "Meteor",
    },
    {
      technologyImage: (
        <img
          className="w-24 h-24 object-contain"
          src="/web-development/technology-logos/My SQL.png"
          alt=""
        />
      ),
      technologyTitle: "MySQL",
    },
    {
      technologyImage: (
        <img
          className="w-24 h-24 object-contain"
          src="/web-development/technology-logos/NEXTJS.png"
          alt=""
        />
      ),
      technologyTitle: "Next.js",
    },
    {
      technologyImage: (
        <img
          className="w-24 h-24 object-contain"
          src="/web-development/technology-logos/Node js.png"
          alt=""
        />
      ),
      technologyTitle: "Node.js",
    },
    {
      technologyImage: (
        <img
          className="w-24 h-24 object-contain"
          src="/web-development/technology-logos/Rails.png"
          alt=""
        />
      ),
      technologyTitle: "Ruby on Rails",
    },
    {
      technologyImage: (
        <img
          className="w-24 h-24 object-contain"
          src="/web-development/technology-logos/Typescript.png"
          alt=""
        />
      ),
      technologyTitle: "TypeScript",
    },
  ];
  return (
    <>
      <section className="overflow-hidden py-14">
        <div className="container mx-auto px-3">
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 max-lg:gap-5 gap-5 items-baseline">
            {webTechnologies.map((data, i) => {
              return (
                <>
                  <div
                    data-aos="zoom-in"
                    className="logo-sm-card h-24 flex cursor-pointer flex-col justify-center items-center text-center"
                    key={i}
                  >
                    <div className="content">
                      <div className="front">{data.technologyImage}</div>
                      <div className="back">
                        <h5 className="text-xl font-medium">
                          {data.technologyTitle}
                        </h5>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}
