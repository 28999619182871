import React from "react";

export default function Header() {
  return (
    <div className="bg-[#222] py-3">
      <div className="container mx-auto px-3 flex justify-end">
        <div className="flex items-center text-[#fff]">
          <h4 className="font-medium tracking-wide max-sm:text-xs text-sm">
            Don't hesitate to text us
          </h4>
          <span className="w-9 max-sm:w-3 h-[2px] bg-white mx-2"></span>
          <a
            href="mailto:info@dignizant.com"
            className="font-light tracking-widest max-sm:text-xs text-sm"
          >
            info@dignizant.com
          </a>
        </div>
      </div>
    </div>
  );
}
