import Aos from "aos";
import React, { useEffect, useRef } from "react";
import Applogoslider from "../components/Applogoslider";
import Weblogoslider from "../components/Weblogoslider";

export default function Home() {
  const sectionRef = useRef(null);
  const handleClick = () => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleNavigation = (url) => {
    window.location.href = url;
  };

  const mobileappcard = [
    {
      id: 1,
      projectname: "Reflect - Daily Relaxation",
      projectimg: (
        <img
          className="w-full h-full object-cover group-hover:scale-110"
          src="mobileapp/1.png"
          alt=""
        />
      ),
    },
    {
      id: 2,
      projectname: "Anuroop Wiwaha | Matrimonial",
      projectimg: (
        <img
          className="w-full h-full object-cover group-hover:scale-110"
          src="mobileapp/2.png"
          alt=""
        />
      ),
    },
    {
      id: 3,
      projectname: "SimplePay",
      projectimg: (
        <img
          className="w-full h-full object-cover group-hover:scale-110"
          src="mobileapp/3.png"
          alt=""
        />
      ),
    },
  ];

  const webdevelopmentcard = [
    {
      id: 1,
      projectname: "USP",
      projectimg: (
        <img
          className="w-full h-full object-cover group-hover:scale-110"
          src="web-development/1.png"
          alt=""
        />
      ),
    },
    {
      id: 2,
      projectname: "PVA Deals",
      projectimg: (
        <img
          className="w-full h-full object-cover group-hover:scale-110"
          src="web-development/2.png"
          alt=""
        />
      ),
    },
    {
      id: 3,
      projectname: "Volume Network",
      projectimg: (
        <img
          className="w-full h-full object-cover group-hover:scale-110"
          src="web-development/3.png"
          alt=""
        />
      ),
    },
  ];

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <div className="h-[95vh] mb-14 max-sm:h-[50vh] bg-[url('https://images.unsplash.com/photo-1555066931-4365d14bab8c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80')] bg-cover bg-center bg-fixed bg-no-repeat w-100 bg-[#222]">
        <div className="w-full h-full backdrop-brightness-50 relative">
          <div className="container mx-auto px-3">
            <div className="py-5">
              <img src="logo.png" alt="" className="w-64 max-sm:w-40" />
            </div>
            <div className="absolute grid place-items-center top-1/2 left-1/2 translate-y-[-50%] translate-x-[-50%] w-full h-full -z-[1]">
              <div className="w-full flex flex-col items-center justify-around max-w-7xl mx-auto">
                <h1 className="text-7xl max-sm:text-3xl font-bold text-white tracking-wide text-center leading-[85px]">
                  Empowering the Future through{" "}
                  <span className="text-[#485BFF]">Software</span> and{" "}
                  <span className="text-[#485BFF]">Technology</span> Development
                </h1>
                <div className="group flex flex-col gap-4 text-center justify-center items-center absolute bottom-10">
                  <h5 className="text-white text-2xl max-sm:text-base group-hover:tracking-wide">
                    Show More
                  </h5>
                  <button
                    onClick={handleClick}
                    className="border border-white h-10 w-10 grid place-items-center group-hover:translate-y-1"
                  >
                    <svg
                      width="16"
                      height="20"
                      viewBox="0 0 16 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15 12L8 19L1 12"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8 19V1"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="py-14" ref={sectionRef}>
        <div className="container mx-auto px-3">
          <div className="flex items-center justify-evenly max-sm:flex-wrap max-sm:gap-5">
            <div
              data-aos="zoom-in"
              data-aos-duration="1000"
              className="text-center border-r border-r-black w-1/4 max-sm:w-52 max-sm:border-none"
            >
              <div className="bg-gradient-to-t text-5xl font-bold from-[#000B6A] to-[#0014CB] text-transparent bg-clip-text mb-3">
                12+
              </div>
              <span className="text-xl font-medium text-center leading-3 tracking-wide">
                Years of <br /> Experience
              </span>
            </div>
            <div
              data-aos="zoom-in"
              data-aos-duration="1000"
              className="text-center border-r border-r-black w-1/4 max-sm:w-52 max-sm:border-none"
            >
              <div className="bg-gradient-to-t text-5xl font-bold from-[#000B6A] to-[#0014CB] text-transparent bg-clip-text mb-3">
                1k+
              </div>
              <span className="text-xl font-medium text-center leading-3 tracking-wide">
                Successful <br /> Projects Delivered
              </span>
            </div>
            <div
              data-aos="zoom-in"
              data-aos-duration="1000"
              className="text-center border-r border-r-black w-1/4 max-sm:w-52 max-sm:border-none"
            >
              <div className="bg-gradient-to-t text-5xl font-bold from-[#000B6A] to-[#0014CB] text-transparent bg-clip-text mb-3">
                75+
              </div>
              <span className="text-xl font-medium text-center leading-3 tracking-wide">
                Our Certified
                <br /> Team
              </span>
            </div>
            <div
              data-aos="zoom-in"
              data-aos-duration="1000"
              className="text-center w-1/4 max-sm:w-52 max-sm:border-none"
            >
              <div className="bg-gradient-to-t text-5xl font-bold from-[#000B6A] to-[#0014CB] text-transparent bg-clip-text mb-3">
                200+
              </div>
              <span className="text-xl font-medium text-center leading-3 tracking-wide">
                Happy Clients
                <br /> Worldwide
              </span>
            </div>
          </div>
        </div>
      </section>

      <section className="py-14 max-sm:pt-0 relative">
        <div className="container mx-auto">
          <div
            data-aos="fade-right"
            data-aos-duration="1000"
            className="bg-[#222] py-20 max-sm:py-10 px-4 flex justify-center items-center gap-7"
          >
            <img src="webdevelopment.png" alt="" />
            <h4 className="text-5xl max-lg:text-3xl max-sm:text-2xl font-semibold text-white tracking-wider">
              Web Development
            </h4>
          </div>
        </div>
      </section>

      <Weblogoslider />

      <section className="py-14">
        <div className="container mx-auto px-3">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 max-lg:gap-5 gap-10 max-sm:gap-5 max-sm:mb-10 mb-16">
            {webdevelopmentcard.map((data, i) => (
              <div
                key={i}
                data-aos="zoom-in"
                className="max-h-[380px] max-sm:h-[300px] p-3 shadow-[0_0_30px_-5px_rgba(0,0,0,0.2)] rounded-2xl hover:scale-[0.950] cursor-pointer"
              >
                <div
                  onClick={() =>
                    handleNavigation(`/projectdetails/${data.projectname}`)
                  }
                  className="group relative h-full overflow-hidden rounded-2xl"
                >
                  {data.projectimg}
                  <div className="group h-full w-full bg-[#0505059e] grid place-items-center absolute top-0 left-0 rounded-2xl">
                    <div className="group text-center flex flex-col items-center justify-center gap-3 p-2">
                      <h4 className="max-sm:text-xl text-2xl max-sm:font-medium font-semibold text-white opacity-80 group-hover:opacity-100">
                        {data.projectname}
                      </h4>
                      <div className="h-0 w-0 overflow-hidden group-hover:h-10 group-hover:w-10">
                        <button className="border border-white h-full w-full grid place-items-center">
                          <svg
                            width="15"
                            height="12"
                            viewBox="0 0 15 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.85712 1L13.8571 6L8.85712 11"
                              stroke="#fff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M13.8571 6L1 6"
                              stroke="#fff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <a
            href="/webdevelopment"
            className="border flex w-48 justify-center items-center gap-3 border-black py-3 px-5 text-base font-medium mx-auto hover:translate-x-2"
          >
            Explore More{" "}
            <svg
              width="20"
              height="8"
              viewBox="0 0 20 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 4H19M19 4L16 7M19 4L16 1"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </a>
        </div>
      </section>

      <section className="py-14 max-sm:pt-0 relative">
        <div className="container mx-auto">
          <div
            data-aos="fade-right"
            data-aos-duration="1000"
            className="bg-[#222] py-20 max-sm:py-10 px-4 flex justify-center items-center gap-7"
          >
            <h4 className="text-5xl max-lg:text-3xl max-sm:text-2xl font-semibold text-white tracking-wider">
              Mobile App Development
            </h4>
            <img src="appdevelopment.png" alt="" />
          </div>
        </div>
      </section>

      <Applogoslider />

      <section className="py-14">
        <div className="container mx-auto px-3">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 max-lg:gap-5 gap-10 max-sm:gap-5 max-sm:mb-10 mb-16">
            {mobileappcard.map((data, i) => (
              <div
                key={i}
                data-aos="zoom-in"
                className="max-h-[380px] max-sm:h-[300px] p-3 shadow-[0_0_30px_-5px_rgba(0,0,0,0.2)] rounded-2xl hover:scale-[0.950] cursor-pointer"
              >
                <div
                  onClick={() =>
                    handleNavigation(`/projectdetails/${data.projectname}`)
                  }
                  className="group relative h-full overflow-hidden rounded-2xl"
                >
                  {data.projectimg}
                  <div className="group h-full w-full bg-[#0505059e] grid place-items-center absolute top-0 left-0 rounded-2xl">
                    <div className="group text-center flex flex-col items-center justify-center gap-3 p-2">
                      <h4 className="max-sm:text-xl text-2xl max-sm:font-medium font-semibold text-white opacity-80 group-hover:opacity-100">
                        {data.projectname}
                      </h4>
                      <div className="h-0 w-0 overflow-hidden group-hover:h-10 group-hover:w-10">
                        <button className="border border-white h-full w-full grid place-items-center">
                          <svg
                            width="15"
                            height="12"
                            viewBox="0 0 15 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.85712 1L13.8571 6L8.85712 11"
                              stroke="#fff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M13.8571 6L1 6"
                              stroke="#fff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <a
            href="/appdevelopment"
            className="border flex w-48 justify-center items-center gap-3 border-black py-3 px-5 text-base font-medium mx-auto hover:translate-x-2"
          >
            Explore More{" "}
            <svg
              width="20"
              height="8"
              viewBox="0 0 20 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 4H19M19 4L16 7M19 4L16 1"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </a>
        </div>
      </section>
    </>
  );
}
