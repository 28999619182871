import React, { useState } from "react";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";

export default function Footer() {
  const [email, setEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const CREATE_SUBSCRIBE = gql`
    mutation CreateSubscribe($email: String) {
      createSubscribe(CreateSubscribeDto: { email: $email }) {
        _id
        email
      }
    }
  `;

  const [setData] = useMutation(CREATE_SUBSCRIBE);

  const [error, setError] = useState({
    email: false,
  });

  const handleEmail = async () => {
    const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const errorObj = {
      email: !email
        ? "Please Enter Email"
        : !emailFormat.test(email)
        ? "Please Enter a valid Email Address"
        : false,
    };

    setError(errorObj);
    const isValidate = Object.values(errorObj).every((item) => item === false);

    const variables = {
      email: email,
    };
    try {
      if (isValidate) {
        const { data } = await setData({
          variables: variables,
        });
        setEmail("");
        setIsSubmitted(true);
        setTimeout(() => {
          setIsSubmitted(false);
        }, 2000);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <>
      <div className="bg-[#222] py-10 mt-20">
        <div className="container mx-auto px-3">
          <div className="flex max-md:flex-col max-md:gap-4 items-center justify-between border-b border-b-white pb-10 mb-10">
            <img src="/white-logo.png" alt="" />
            <div className="flex flex-col">
              <div className="flex items-center bg-white rounded-2xl max-sm:rounded-xl p-2 max-w-md max-md:max-w-none w-full justify-between">
                <input
                  type="text"
                  placeholder="Enter Your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full text-base max-sm:text-sm font-normal tracking-wider px-3"
                />
                <button
                  onClick={handleEmail}
                  className="bg-[#222] text-white py-3 px-5 rounded-xl max-sm:text-sm text-xl font-medium tracking-wider hover:tracking-widest uppercase"
                >
                  Subscribe
                </button>
              </div>
              {error.email && (
                <small className="mt-1 text-red-500">{error.email}</small>
              )}
            </div>
          </div>
          <h6 className="text-center text-white text-xs tracking-wider">
            © Copyright 2023 - Dignizant Technolgies
          </h6>
        </div>
      </div>
      {isSubmitted && (
        <div className="popup-message">Submitted successfully!</div>
      )}
    </>
  );
}
