import React from "react";
import Webdevelopment from "./pages/Webdevelopment";
import Appdevelopment from "./pages/Appdevelopment";
import Home from "./pages/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Projectdetails from "./pages/Projectdetails";
import "aos/dist/aos.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

function App() {
  return (
    <BrowserRouter>
      <div className="bg"></div>
      <div className="bg bg2"></div>
      <div className="bg bg3"></div>
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/webdevelopment" element={<Webdevelopment />} />
        <Route path="/appdevelopment" element={<Appdevelopment />} />
        <Route
          exact
          path="/projectdetails/:projectName"
          element={<Projectdetails />}
        />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
