import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProjectData from "./Projectdata.js";
import Aos from "aos";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import { Autoplay, Navigation } from "swiper/modules";

SwiperCore.use([Autoplay, Navigation]);

export default function Projectdetails() {
  const { projectName } = useParams();

  const [projectDetail, setprojectDetail] = useState({});

  useEffect(() => {
    const innerdetails = ProjectData.find(
      (res) => res.projectTitle === projectName
    );
    setprojectDetail(innerdetails);
  }, [projectName]);

  useEffect(() => {
    Aos.init();
  }, []);

  const appbreakpoints = {
    320: {
      slidesPerView: 1,
    },
    480: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 3,
    },
    1024: {
      slidesPerView: 4,
    },
    1280: {
      slidesPerView: 5,
    },
  };

  const webbreakpoints = {
    320: {
      slidesPerView: 1,
    },
    480: {
      slidesPerView: 1,
    },
    768: {
      slidesPerView: 1,
    },
    1024: {
      slidesPerView: 2,
    },
    1280: {
      slidesPerView: 2,
    },
  };

  return (
    <>
      <div className="container mx-auto py-5 px-3">
        <a href="/" className="block">
          <img src="/main-logo.png" alt="" className="w-60 max-sm:w-40" />
        </a>
      </div>
      <section className="py-14 overflow-hidden">
        <div className="container mx-auto px-3">
          <div className="flex items-center max-lg:flex-col max-lg:gap-10">
            <div
              data-aos="fade-right"
              data-aos-duration="2000"
              className="w-2/4 max-lg:w-full pl-7 max-lg:pl-0"
            >
              <div className="custom-dots-left">
                <span className="mt-4 dots-title"></span>
                <h2 className="font-semibold text-5xl max-sm:text-2xl max-w-2xl">
                  {projectDetail?.projectTitle}
                </h2>
              </div>
              <div className="custom-dots-left">
                <span></span>
                <h2 className="text-xl max-sm:text-base font-normal max-w-2xl">
                  {projectDetail?.summaries}
                </h2>
              </div>
              {projectDetail?.summaries2 && (
                <div className="custom-dots-left">
                  <span></span>
                  <h2 className="text-xl max-sm:text-base font-normal max-w-2xl">
                    {projectDetail?.summaries2}
                  </h2>
                </div>
              )}
              {projectDetail?.summaries3 && (
                <div className="custom-dots-left">
                  <span></span>
                  <h2 className="text-xl max-sm:text-base font-normal max-w-2xl">
                    {projectDetail?.summaries3}
                  </h2>
                </div>
              )}
              {projectDetail?.summaries4 && (
                <div className="custom-dots-left">
                  <span></span>
                  <h2 className="text-xl max-sm:text-base font-normal max-w-2xl">
                    {projectDetail?.summaries4}
                  </h2>
                </div>
              )}

              {projectDetail.subSummaries && (
                <>
                  {projectDetail?.subSummaries.map((option, i) => {
                    return (
                      <>
                        {option.title && (
                          <div className="custom-dots-left custom-inner-dots">
                            <h2 className="font-semibold text-lg text-left max-w-2xl pl-10">
                              {option.title}
                            </h2>
                          </div>
                        )}
                        {option.description && (
                          <div className="custom-dots-left custom-inner-dots">
                            <span></span>
                            <p
                              key={i}
                              className="text-base font-normal text-left max-w-2xl mb-3"
                            >
                              {option.description}
                            </p>
                          </div>
                        )}
                      </>
                    );
                  })}
                </>
              )}

              <div className="flex items-center gap-3 flex-wrap mx-10 my-4">
                {projectDetail.playstorelink && (
                  <a
                    className="block hover:scale-[0.95]"
                    href={projectDetail?.playstorelink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="w-40"
                      src="/mobileapp/playstore-btn.png"
                      alt=""
                    />
                  </a>
                )}
                {projectDetail.appstorelink && (
                  <a
                    className="block hover:scale-[0.95]"
                    href={projectDetail?.appstorelink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="w-40"
                      src="/mobileapp/appstore-btn.png"
                      alt=""
                    />
                  </a>
                )}
              </div>

              {projectDetail?.projectlink && (
                <div className="hover:scale-[0.95] inline-block">
                  <a
                    className="border inline-block mx-10 border-black py-3 px-10 text-base font-medium"
                    href={projectDetail?.projectlink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Visit Site
                  </a>
                </div>
              )}
            </div>
            <div
              data-aos="fade-left"
              data-aos-delay="300"
              data-aos-duration="2000"
              className="w-2/4 max-lg:w-full"
            >
              <div
                className={
                  projectDetail.projecttype === "webdevelopment"
                    ? "max-w-2xl mx-auto max-lg:max-w-xs"
                    : "max-w-sm mx-auto max-lg:max-w-xs"
                }
              >
                {projectDetail?.projectimg1}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-14">
        <div className="container mx-auto px-3">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 max-lg:gap-5">
            {projectDetail?.infoDetails?.map((data, i) => (
              <>
                <div
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                  key={i}
                  className="px-5 text-center border-r border-r-black max-lg:border-none last:border-r-0"
                >
                  <div className="bg-gradient-to-t text-4xl font-bold from-[#000B6A] to-[#0014CB] text-transparent bg-clip-text mb-5">
                    {data.title}
                  </div>
                  <span className="text-2xl max-xl:text-xl font-semibold text-center leading-8 tracking-wide px-1">
                    {data.description}
                  </span>
                </div>
              </>
            ))}
          </div>
        </div>
      </section>

      <section className="py-14 overflow-hidden">
        <div className="container mx-auto px-3">
          <div className="flex items-center max-lg:flex-col-reverse max-lg:gap-10">
            <div
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="300"
              className="w-2/4 max-lg:w-full"
            >
              <div
                className={
                  projectDetail.projecttype === "webdevelopment"
                    ? "max-w-2xl mx-auto max-lg:max-w-xs"
                    : "max-w-sm mx-auto max-lg:max-w-xs"
                }
              >
                {projectDetail?.projectimg2}
              </div>
            </div>
            <div
              data-aos="fade-left"
              data-aos-duration="1000"
              className="w-2/4 max-lg:w-full pr-7 max-lg:pr-0"
            >
              {projectDetail.challenges && (
                <div className="custom-dots-left">
                  <span className="mt-4 dots-title"></span>
                  {projectDetail.challenges && (
                    <h2 className="font-semibold text-5xl max-sm:text-2xl max-w-2xl">
                      Challenges
                    </h2>
                  )}
                </div>
              )}
              {projectDetail.challenges && (
                <div className="custom-dots-left">
                  <span></span>
                  <h2 className="text-xl font-normal max-w-2xl">
                    {projectDetail?.challenges}
                  </h2>
                </div>
              )}

              {projectDetail?.aboutUs && (
                <div className="custom-dots-left">
                  <span></span>
                  <h2 className="text-xl font-normal max-w-2xl">
                    {projectDetail?.aboutUs}
                  </h2>
                </div>
              )}

              {projectDetail?.aboutUs2 && (
                <div className="custom-dots-left">
                  <span></span>
                  <h2 className="text-xl font-normal max-w-2xl">
                    {projectDetail?.aboutUs2}
                  </h2>
                </div>
              )}

              {projectDetail?.challenges2 && (
                <div className="custom-dots-left">
                  <span></span>
                  <h2 className="text-xl font-normal max-w-2xl">
                    {projectDetail?.challenges2}
                  </h2>
                </div>
              )}

              {projectDetail?.challenges3 && (
                <div className="custom-dots-left">
                  <span></span>
                  <h2 className="text-xl font-normal max-w-2xl">
                    {projectDetail?.challenges3}
                  </h2>
                </div>
              )}

              {projectDetail?.challenges4 && (
                <div className="custom-dots-left">
                  <span></span>
                  <h2 className="text-xl font-normal max-w-2xl">
                    {projectDetail?.challenges4}
                  </h2>
                </div>
              )}

              {projectDetail.subChallenges && (
                <>
                  {projectDetail?.subChallenges.map((option, i) => {
                    return (
                      <>
                        <div className="custom-dots-left custom-inner-dots">
                          <h2 className="font-semibold text-lg max-w-2xl pl-10">
                            {option.title}
                          </h2>
                        </div>
                        {option.description && (
                          <div className="custom-dots-left custom-inner-dots">
                            <span></span>
                            <p
                              key={i}
                              className="text-base font-normal max-w-2xl mb-3"
                            >
                              {option.description}
                            </p>
                          </div>
                        )}
                      </>
                    );
                  })}
                </>
              )}

              {projectDetail?.challenges5 && (
                <div className="custom-dots-left">
                  <span></span>
                  <h2 className="text-xl font-normal max-w-2xl">
                    {projectDetail?.challenges5}
                  </h2>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="py-14 overflow-hidden">
        <div className="container mx-auto px-3">
          <div className="flex items-center max-lg:flex-col max-lg:gap-10">
            <div
              data-aos="fade-right"
              data-aos-duration="1000"
              className="w-2/4 max-lg:w-full pl-7 max-lg:pl-0"
            >
              {projectDetail.solution && (
                <div className="custom-dots-left">
                  <span className="mt-4 dots-title"></span>
                  {projectDetail.solution && (
                    <h2 className="font-semibold text-5xl text-left max-sm:text-2xl max-w-2xl">
                      Solution
                    </h2>
                  )}
                </div>
              )}
              {projectDetail?.solution && (
                <div className="custom-dots-left">
                  <span></span>
                  <h2 className="text-xl font-normal text-left max-w-2xl">
                    {projectDetail?.solution}
                  </h2>
                </div>
              )}
              {projectDetail?.moreAbout && (
                <div className="custom-dots-left">
                  <span></span>
                  <h2 className="text-xl font-normal text-left max-w-2xl">
                    {projectDetail?.moreAbout}
                  </h2>
                </div>
              )}
              {projectDetail?.moreAbout2 && (
                <div className="custom-dots-left">
                  <span></span>
                  <h2 className="text-xl font-normal text-left max-w-2xl">
                    {projectDetail?.moreAbout2}
                  </h2>
                </div>
              )}

              {projectDetail?.solution2 && (
                <div className="custom-dots-left">
                  <span></span>
                  <h2 className="text-xl font-normal max-w-2xl text-left">
                    {projectDetail?.solution2}
                  </h2>
                </div>
              )}

              {projectDetail?.solution3 && (
                <div className="custom-dots-left">
                  <span></span>
                  <h2 className="text-xl font-normal max-w-2xl text-left">
                    {projectDetail?.solution3}
                  </h2>
                </div>
              )}
              {projectDetail.subSolution && (
                <>
                  {projectDetail?.subSolution.map((option, i) => {
                    return (
                      <>
                        <div className="custom-dots-left custom-inner-dots">
                          <h2 className="font-semibold text-lg text-left max-w-2xl pl-10">
                            {option.title}
                          </h2>
                        </div>
                        {option.description && (
                          <div className="custom-dots-left custom-inner-dots">
                            <span></span>
                            <p
                              key={i}
                              className="text-base font-normal text-left max-w-2xl mb-3"
                            >
                              {option.description}
                            </p>
                          </div>
                        )}
                      </>
                    );
                  })}
                </>
              )}

              {projectDetail?.solution4 && (
                <div className="custom-dots-left">
                  <span></span>
                  <h2 className="text-xl font-normal max-w-2xl text-left">
                    {projectDetail?.solution4}
                  </h2>
                </div>
              )}
              {projectDetail?.solution5 && (
                <div className="custom-dots-left">
                  <span></span>
                  <h2 className="text-xl font-normal max-w-2xl text-left">
                    {projectDetail?.solution5}
                  </h2>
                </div>
              )}

              {projectDetail.features && (
                <>
                  <div className="custom-dots-left">
                    <span className="mt-4 dots-title"></span>
                    <h2 className="font-semibold text-5xl text-left max-w-2xl">
                      Features
                    </h2>
                  </div>
                  {projectDetail?.features.map((option, i) => (
                    <div key={i} className="custom-dots-left custom-inner-dots">
                      <span className="mt-4 dots-title"></span>
                      <h2 className="font-medium text-lg text-left max-w-2xl">
                        {option.title}
                      </h2>
                    </div>
                  ))}
                </>
              )}
            </div>
            <div
              data-aos="fade-left"
              data-aos-duration="1000"
              data-aos-delay="300"
              className="w-2/4 max-lg:w-full"
            >
              <div
                className={
                  projectDetail.projecttype === "webdevelopment"
                    ? "max-w-2xl mx-auto max-lg:max-w-xs"
                    : "max-w-sm mx-auto max-lg:max-w-xs"
                }
              >
                {projectDetail?.projectimg3}
              </div>
            </div>
          </div>
        </div>
      </section>

      {projectDetail.productFeatures && (
        <section className="py-14">
          <div className="container mx-auto px-3">
            <div className="text-center">
              <h2 className="font-semibold text-5xl text-center max-sm:text-2xl mb-5">
                Key Features
              </h2>
            </div>

            {projectDetail.productFeatures && (
              <>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 max-lg:gap-4 gap-10 max-sm:gap-2 mt-10 mb-16">
                  {projectDetail?.productFeatures.map((option, i) => (
                    <div
                      data-aos="zoom-in"
                      data-aos-duration="1000"
                      key={i}
                      className="p-4 bg-white rounded-lg shadow-lg flex flex-col justify-evenly"
                    >
                      <span className="text-xl block font-semibold text-center">
                        {option.title}
                      </span>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </section>
      )}

      <section className="px-4">
        {projectDetail.projecttype === "appdevelopment" ? (
          <Swiper
            navigation={true}
            modules={[Navigation]}
            className="mySwiper"
            effect="fade"
            loop={true}
            breakpoints={appbreakpoints}
            autoplay={{ delay: 2000 }}
          >
            {projectDetail.ourView && (
              <>
                {projectDetail?.ourView.map((option, i) => {
                  return (
                    <>
                      <SwiperSlide key={i}>{option.viewImages}</SwiperSlide>
                    </>
                  );
                })}
              </>
            )}
          </Swiper>
        ) : (
          <div className="container mx-auto">
            <Swiper
              navigation={true}
              modules={[Navigation]}
              className="mySwiper"
              effect="fade"
              loop={true}
              breakpoints={webbreakpoints}
              autoplay={{ delay: 2000 }}
            >
              {projectDetail.ourView && (
                <>
                  {projectDetail?.ourView.map((option, i) => {
                    return (
                      <>
                        <SwiperSlide key={i}>{option.viewImages}</SwiperSlide>
                      </>
                    );
                  })}
                </>
              )}
            </Swiper>
          </div>
        )}
      </section>
    </>
  );
}
