const ProjectData = [
  {
    projectTitle: "Anuroop Wiwaha | Matrimonial",
    projecttype: "appdevelopment",
    playstorelink:
      "https://play.google.com/store/apps/details?id=com.app.anuroop&hl=en_IN&gl=US",
    appstorelink: "https://apps.apple.com/in/app/anuroop/id1189015020",
    projectimg1: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/mobileapp/project-images/anuroop/1.png"
        alt=""
      />
    ),
    projectimg2: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/mobileapp/project-images/anuroop/2.png"
        alt=""
      />
    ),
    projectimg3: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/mobileapp/project-images/anuroop/3.png"
        alt=""
      />
    ),
    projectimg4: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/mobileapp/project-images/anuroop/4.png"
        alt=""
      />
    ),
    summaries:
      "Find your life partner on the go! Introducing Anuroop Wiwaha mobile app!",
    infoDetails: [
      {
        title: "Industry",
        description: "Matrimonial",
      },
      {
        title: "Services",
        description: "Application",
      },
      {
        title: "Country",
        description: "India",
      },
      {
        title: "Tech Stacks",
        description: ["Provider State Management,", "MVVM structure"],
      },
    ],
    subSummaries: [
      {
        title: "100000+ happy marriages",
      },
      {
        title: "48 years of experience",
      },
      {
        title: "1000+ matrimony-related events conducted worldwide",
      },
      {
        title: "100% of our members receive personal assistance",
      },
    ],
    challenges:
      "The Anuroop Wiwaha app faced the challenge of efficiently handling a million requests simultaneously while providing the best possible user experience. The massive influx of requests put immense pressure on the app's servers and infrastructure, leading to potential performance bottlenecks and decreased responsiveness.",
    subSolution: [
      {
        title: "Scalability and Load Balancing",
      },
      {
        title: "Caching and Database Optimisation",
      },
      {
        title: "Asynchronous Processing",
      },
      {
        title: "Content Delivery Network (CDN) Implementation",
      },
      {
        title: "Resource Optimisation",
      },
      {
        title: "Monitoring and Auto-Scaling",
      },
    ],
    solution:
      "To overcome this challenge, the development team had to implement robust solutions, including :",
    solution4:
      "As a result of these solutions, the Anuroop Wiwaha app successfully managed to handle a million requests at a time while delivering an improved and seamless user experience, even under high loads. Users experienced reduced waiting times and increased app responsiveness, leading to higher user satisfaction and retention rates.",
    productFeatures: [
      {
        title: "Who Viewed My Profile",
      },
      {
        title: "Interests",
      },
      {
        title: "Compatibility Match Based on User Preferences",
      },
      {
        title: "Live Chat",
      },
    ],
    ourView: [
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/anuroop/overview/1.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/anuroop/overview/2.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/anuroop/overview/3.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/anuroop/overview/4.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/anuroop/overview/5.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/anuroop/overview/6.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/anuroop/overview/7.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/anuroop/overview/8.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/anuroop/overview/9.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/anuroop/overview/10.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/anuroop/overview/11.png"
            alt=""
          />
        ),
      },
    ],
  },
  {
    projectTitle: "Reflect - Daily Relaxation",
    projecttype: "appdevelopment",
    playstorelink:
      "https://play.google.com/store/apps/details?id=com.meetreflect.us",
    appstorelink: "",
    projectimg1: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/mobileapp/project-images/ReflectApp/1.png"
        alt=""
      />
    ),
    projectimg2: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/mobileapp/project-images/ReflectApp/2.png"
        alt=""
      />
    ),
    projectimg3: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/mobileapp/project-images/ReflectApp/3.png"
        alt=""
      />
    ),
    projectimg4: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/mobileapp/project-images/ReflectApp/4.png"
        alt=""
      />
    ),
    summaries:
      "Unique Relaxation & Meditation app that pairs with a biofeedback companion device (the Reflect Orb)",
    summaries2:
      "Designed with your well-being in mind, this personalised app allows you to do the following :",
    infoDetails: [
      {
        title: "Industry",
        description: "Health & Fitness",
      },
      {
        title: "Services",
        description: "Application",
      },
      {
        title: "Country",
        description: "Israel",
      },
      {
        title: "Tech Stacks",
        description: ["Bloc State Management, ", "MVVM Structure"],
      },
    ],
    subSummaries: [
      {
        title:
          "Track the progress and impact of your practice as you journey daily with your Orb",
      },
      {
        title:
          "Enrich your experience with knowledge and insights curated by experts",
      },
    ],
    techStacks: [
      {
        title: "Mvvm Structure,",
      },
      {
        title: "UI-UX,",
      },
      {
        title: "Bloc State Management,",
      },
      {
        title: "Responsive and Adaptive Design,",
      },
      {
        title: "Supports Multi Themes",
      },
    ],
    challenges:
      "The Reflect meditation app faced challenges in utilising a custom graph to visualise vast data for biofeedback scrolling.",
    challenges2:
      "This data set contained over 1000+ data points in a list, causing significant lag during scrolling, especially when navigating from the top to the bottom of the graph.",
    challenges3:
      "The lagging issue hindered users from experiencing smooth and seamless interactions with the app, potentially impacting the overall user experience.",
    challenges4:
      "The development team had to address this performance concern to enhance user satisfaction and ensure the app's usability, especially for users dealing with extensive biofeedback data.",
    solution:
      "To address the challenge of lagging during biofeedback scrolling caused by a large data set, Our development team implemented an effective solution",
    solution2:
      "Single-Child Scroll with Physics: The team restructured the custom graph's scrolling behaviour by adopting a single-child scroll with physics approach. This technique involved optimising the graph's rendering and scrolling mechanism to use a single child element, enabling smoother and more efficient scrolling performance.",
    solution3:
      "By implementing physics-based scrolling, the team allowed the graph to mimic real-world motion, making the scrolling experience feel natural and responsive. The physics-based animation ensured that the graph elements smoothly transitioned during scrolling, eliminating the previous lag issues.",
    solution4:
      "As a result of these solutions, the Reflect meditation app successfully overcame the lagging issue during biofeedback scrolling. Users could now navigate through the custom graph seamlessly, experiencing smooth and responsive interactions with the app. The improved scrolling performance enhanced the overall user experience, especially for users dealing with extensive biofeedback data, leading to increased user satisfaction and better app usability.",
    subSolution: [
      { title: "Data Chunking" },
      { title: "Asynchronous Rendering" },
      { title: "Optimised Memory Management" },
    ],
    productFeatures: [
      {
        title: "Who Viewed My Profile",
      },
      {
        title: "Take a Moment",
      },
      {
        title: "Explore",
      },
      {
        title: "Music Player",
      },
    ],
    ourView: [
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/ReflectApp/overview/1.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/ReflectApp/overview/2.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/ReflectApp/overview/3.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/ReflectApp/overview/4.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/ReflectApp/overview/5.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/ReflectApp/overview/6.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/ReflectApp/overview/7.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/ReflectApp/overview/8.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/ReflectApp/overview/9.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/ReflectApp/overview/10.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/ReflectApp/overview/11.png"
            alt=""
          />
        ),
      },
    ],
  },
  {
    projectTitle: "SimplePay",
    projecttype: "appdevelopment",
    playstorelink:
      "https://play.google.com/store/apps/details?id=cloud.simplepay.mobile.v1",
    appstorelink: "https://apps.apple.com/in/app/simplepay/id1501139428",
    projectimg1: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/mobileapp/project-images/SimplePay/1.png"
        alt=""
      />
    ),
    projectimg2: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/mobileapp/project-images/SimplePay/2.png"
        alt=""
      />
    ),
    projectimg3: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/mobileapp/project-images/SimplePay/3.png"
        alt=""
      />
    ),
    projectimg4: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/mobileapp/project-images/SimplePay/4.png"
        alt=""
      />
    ),
    summaries:
      "The power of SimplePay’s Self-Service offering in the palm of your hand!",
    infoDetails: [
      {
        title: "Industry",
        description: "Fintech",
      },
      {
        title: "Services",
        description: "Application",
      },
      {
        title: "Country",
        description: "South Africa",
      },
      {
        title: "Tech Stacks",
        description: ["Bloc State Management,", "Multi Languages Supported"],
      },
    ],
    challenges:
      "Large businesses find it impractical to handle hundreds of employees, vendors, and finances manually. Therefore, our client presented the following requirements for application development, which posed significant challenges for the development team :",
    subChallenges: [
      {
        title: "Finance Management",
      },
      {
        title: "Mobility and Accessibility",
      },
      {
        title: "User Dashboard Functionality",
      },
      {
        title: "Vendor Management",
      },
    ],
    solution4:
      "Overall, This app addresses the unique challenges faced by large businesses in HR management, providing an all-in-one solution that optimizes finance management, enhances mobility, streamlines dashboard functionality, and simplifies vendor interactions for a seamless and efficient HR experience.",
    subSolution: [
      {
        title: "Finance Management :",
        description:
          "To address the challenge of managing finances for large businesses, our app provides a comprehensive HR management system with advanced finance features. The app streamlines payroll processing, automates payment workflows, and accurately calculates salaries to ensure error-free financial operations. Additionally, it includes robust auditing and reporting capabilities to facilitate transparent financial management and compliance.",
      },
      {
        title: "Mobility and Accessibility :",
        description:
          "To enhance mobility and accessibility, our app offers a user-friendly and feature-rich mobile application. With this app, users can access critical HR functions on the go, including leave applications, shift scheduling, and real-time attendance tracking. The mobile app enables seamless data synchronization, ensuring that employees and managers can stay updated with essential information anytime, anywhere.",
      },
      {
        title: "User Dashboard Functionality :",
        description:
          "Our app boasts an intuitive and efficient user dashboard that centralizes all HR-related information. The dashboard provides an organized view of payroll data, leave statuses, and approval workflows across companies. Its user-friendly interface allows easy data retrieval and sharing, facilitating smooth collaboration and information exchange among team members.",
      },
      {
        title: "Vendor Management :",
        description:
          "To simplify vendor management, our app integrates a comprehensive module that handles vendor payments and compliance. It streamlines payment processes, ensuring timely and accurate vendor transactions. The app's vendor management module also incorporates tax calculations and compliance checks, alleviating the burden on users and facilitating seamless vendor interactions.",
      },
    ],
    productFeatures: [
      {
        title: "Secure Login",
      },
      {
        title: "Leave Request",
      },
      {
        title: "Leave Approval",
      },

      {
        title: "More Features",
      },
    ],
    ourView: [
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/SimplePay/overview/1.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/SimplePay/overview/2.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/SimplePay/overview/3.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/SimplePay/overview/4.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/SimplePay/overview/5.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/SimplePay/overview/6.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/SimplePay/overview/7.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/SimplePay/overview/8.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/SimplePay/overview/9.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/SimplePay/overview/10.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/SimplePay/overview/11.png"
            alt=""
          />
        ),
      },
    ],
    result:
      "We have had a lot of positive feedback on the application from the client.",
    result1:
      "The client has successfully developed a well-designed and integrated payroll Management System application, that will create robust companies and that will be both agile and adaptive.",
    result2:
      "Mobile application comes with the client’s 100% requirement and has given them a high level of satisfaction.",
    result3:
      "After reviewing the mobile design, the client was very happy. The the mobile app was exactly looking at what the client wanted.",
    result4: "Developer ensured 100% adherence to the client for requirements.",
  },
  {
    projectTitle: "Subtraid",
    projecttype: "appdevelopment",
    playstorelink:
      "https://play.google.com/store/apps/details?id=com.app.subtraid",
    appstorelink: "https://apps.apple.com/in/app/subtraid/id1630914245",
    projectimg1: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/mobileapp/project-images/Subtraid/1.png"
        alt=""
      />
    ),
    projectimg2: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/mobileapp/project-images/Subtraid/2.png"
        alt=""
      />
    ),
    projectimg3: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/mobileapp/project-images/Subtraid/3.png"
        alt=""
      />
    ),
    projectimg4: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/mobileapp/project-images/Subtraid/4.png"
        alt=""
      />
    ),
    summaries:
      "Subtraid brings construction management into the technological age, lowering overhead and streamlining the overall cost of running your business.",
    summaries2:
      "The all-in-one digital solution is a cloud-based service designed to optimise your company's performance and strengthen key business relationships - general contractors, subcontractors, staff, and suppliers.",
    infoDetails: [
      {
        title: "Industry",
        description: "Construction",
      },
      {
        title: "Services",
        description: "Application",
      },
      {
        title: "Country",
        description: "Canada",
      },
      {
        title: "Tech Stacks",
        description: ["Get-x State Management,", "MVVM Structure"],
      },
    ],
    challenges:
      "The Subtraid app faced several challenges in order to enhance its functionality and provide users with a seamless experience:",
    subChallenges: [
      {
        title: "Real-Time Chat Feature :",
        description:
          "Implementing a socket-based chat feature posed a significant challenge for the development team. The requirement to ensure real-time communication between users demanded efficient data synchronization and message delivery mechanisms to create a smooth and responsive chat experience.",
      },
      {
        title: "Pagination for Chat Messages :",
        description:
          "Integrating pagination for organizing and navigating chat messages presented another obstacle. The app needed to handle the loading and display of chat history in a user-friendly manner, especially when dealing with large volumes of messages, to facilitate easy message access and retrieval.",
      },
      {
        title: "File Upload Functionality :",
        description:
          "Enabling file uploads within the chat platform proved to be a complex task. Integrating with external file storage services such as Google Drive, Microsoft OneDrive, and Dropbox required careful API integration to facilitate seamless file sharing and storage capabilities. The app also needed to ensure secure user authentication and authorization to access these external services.",
      },
    ],
    solution:
      "The development team has implemented effective solutions to enhance its functionality and provide users with a seamless experience.",
    subSolution: [
      {
        title: "Real-Time Chat Feature :",
        description:
          "To address the challenge of implementing a socket-based chat feature, we developed efficient data synchronisation and message delivery mechanisms. This ensures real-time communication between users, creating a smooth and responsive chat experience.",
      },
      {
        title: "Pagination for Chat Messages :",
        description:
          "We successfully integrated pagination functionality, allowing users to organize and navigate chat messages effortlessly. Even with large volumes of messages, the app now handles loading and display in a user-friendly manner, facilitating easy message access and retrieval.",
      },
      {
        title: "File Upload Functionality :",
        description:
          "Enabling file uploads within the chat platform required careful API integration with popular cloud storage services like Google Drive, Microsoft OneDrive, and Dropbox. Our solution ensures seamless file sharing and storage capabilities, along with robust user authentication and authorisation for secure access to these external services.",
      },
      {
        title: "Vendor Management :",
        description:
          "To simplify vendor management, our app integrates a comprehensive module that handles vendor payments and compliance. It streamlines payment processes, ensuring timely and accurate vendor transactions. The app's vendor management module also incorporates tax calculations and compliance checks, alleviating the burden on users and facilitating seamless vendor interactions.",
      },
    ],
    solution4:
      "Additionally, we implemented state management to effectively manage the app state, leading to smooth and optimised performance throughout the application.",
    productFeatures: [
      {
        title: "Secure Login",
      },
      {
        title: "Who Viewed My Profile",
      },
      {
        title: "Create Project",
      },
      {
        title: "Create and View Sub Project",
      },
    ],
    ourView: [
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/Subtraid/overview/1.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/Subtraid/overview/2.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/Subtraid/overview/3.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/Subtraid/overview/4.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/Subtraid/overview/5.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/Subtraid/overview/6.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/Subtraid/overview/7.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/Subtraid/overview/8.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/Subtraid/overview/9.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/Subtraid/overview/10.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/Subtraid/overview/11.png"
            alt=""
          />
        ),
      },
    ],
  },
  {
    projectTitle: "Sawa",
    projecttype: "appdevelopment",
    playstorelink:
      "https://play.google.com/store/apps/details?id=com.adsum.sawa&hl=en&gl=US&pli=1",
    appstorelink: "https://apps.apple.com/kw/app/sawa-app/id1573402170",
    projectimg1: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/mobileapp/project-images/sawa/1.png"
        alt=""
      />
    ),
    projectimg2: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/mobileapp/project-images/sawa/2.png"
        alt=""
      />
    ),
    projectimg3: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/mobileapp/project-images/sawa/3.png"
        alt=""
      />
    ),
    projectimg4: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/mobileapp/project-images/sawa/4.png"
        alt=""
      />
    ),
    summaries:
      "A large assortment of food baskets, foods, electrical and electronic tools, clothes and gifts of all kinds.",
    summaries2:
      "Paying in more than one currency according to the currency of the country in which you reside.",
    summaries3:
      "Guarantee of order delivery and the ability to recover money in the event that it is not delivered.",
    infoDetails: [
      {
        title: "Industry",
        description: "Shopping",
      },
      {
        title: "Services",
        description: "Application",
      },
      {
        title: "Country",
        description: "Dubai",
      },
      {
        title: "Tech Stacks",
        description: ["Get-x State Management,", "MVVM Structure"],
      },
    ],
    challenges:
      "To integrate custom payment gateway which is supported mostly arabian countries.",
    solution:
      " I was worked very hard like done deeply R&D regarding the things also if i could not found the solution using R&D then i take help from some of my seniors to short out the some of difficulties and finally i was accomplished them.",
    productFeatures: [
      {
        title: "More than 1000+ Stores",
      },
      {
        title: "Competitive Prices",
      },
      {
        title: "Continuous Offers",
      },
      {
        title: "Secure Payment Methods",
      },
    ],
    ourView: [
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/sawa/overview/1.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/sawa/overview/2.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/sawa/overview/3.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/sawa/overview/4.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/sawa/overview/5.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/sawa/overview/6.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/sawa/overview/7.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/sawa/overview/8.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/sawa/overview/9.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/sawa/overview/5.png"
            alt=""
          />
        ),
      },
    ],
  },
  {
    projectTitle: "AI Smart Chat",
    projecttype: "appdevelopment",
    playstorelink:
      "https://play.google.com/store/apps/details?id=com.digni.smartchat",
    projectimg1: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/mobileapp/project-images/aismartchat/1.png"
        alt=""
      />
    ),
    projectimg2: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/mobileapp/project-images/aismartchat/2.png"
        alt=""
      />
    ),
    projectimg3: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/mobileapp/project-images/aismartchat/3.png"
        alt=""
      />
    ),
    projectimg4: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/mobileapp/project-images/aismartchat/4.png"
        alt=""
      />
    ),
    summaries:
      "AI Smart Chat is an innovative chatbot solution that utilizes advanced ChatGPT technology to provide users with instant and smart answers to their questions. The chatbot is a personal AI assistant that delivers interactive and fun conversations to help users become more productive and efficient in their daily lives.",
    infoDetails: [
      {
        title: "Industry",
        description: "Artificial Intelligence",
      },
      {
        title: "Services",
        description: "Application",
      },
      {
        title: "Country",
        description: "India",
      },
      {
        title: "Tech Stacks",
        description: ["Get-x State Management,", "MVVM Structure"],
      },
    ],
    aboutUs:
      "ChatGPT is an advanced AI Smart Chatbot that uses Natural Language Processing (NLP) and Machine Learning algorithms to provide instant and smart answers to your questions. With its vast database of knowledge and ability to understand context, ChatGPT delivers interactive and fun conversations that make you more productive.",
    aboutUs2:
      "Whether you’re a student looking for help with homework, a professional seeking information about a particular industry, or just someone who wants to chat with an AI assistant, ChatGPT is the perfect solution for all your needs. With its advanced algorithms and ability to learn from its interactions, ChatGPT provides accurate and relevant information that is tailored to your specific needs.",
    moreAbout:
      "One of the key advantages of using ChatGPT AI Smart Chat is its ability to understand natural language. Unlike traditional chatbots that require users to input specific keywords or phrases, ChatGPT can understand complex sentences and respond in a way that feels like a real conversation.",
    moreAbout2:
      "Overall, ChatGPT-AI Smart Chat is an AI-powered chatbot that is revolutionizing the way we interact with technology. With its ability to understand natural language, vast knowledge base, and constant learning and improvement, ChatGPT is the perfect solution for anyone looking to get instant and smart answers to their questions. So why wait? Try ChatGPT - AI Smart Chat today and experience the future of AI chatbot!",
    productFeatures: [
      {
        title: "One Time Purchase",
      },
      {
        title: "Speech to Text",
      },
      {
        title: "AI Assistant",
      },
      {
        title: "Natural Language Processing",
      },
    ],
    ourView: [
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/aismartchat/overview/1.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/aismartchat/overview/2.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/aismartchat/overview/3.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/aismartchat/overview/4.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/aismartchat/overview/5.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/aismartchat/overview/6.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/aismartchat/overview/7.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/aismartchat/overview/8.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/aismartchat/overview/9.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/aismartchat/overview/10.png"
            alt=""
          />
        ),
      },
    ],
  },
  {
    projectTitle: "Clarity Go",
    projecttype: "appdevelopment",
    playstorelink:
      "https://play.google.com/store/apps/details?id=com.optovia.clarityapp",
    appstorelink: "https://apps.apple.com/in/app/clarity-go/id1578686240",
    projectimg1: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/mobileapp/project-images/ClarityGo/1.png"
        alt=""
      />
    ),
    projectimg2: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/mobileapp/project-images/ClarityGo/2.png"
        alt=""
      />
    ),
    projectimg3: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/mobileapp/project-images/ClarityGo/3.png"
        alt=""
      />
    ),
    projectimg4: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/mobileapp/project-images/ClarityGo/4.png"
        alt=""
      />
    ),
    summaries:
      "Clarity Go is a new way to easily access your Clarity data when you need to. No more having to pull out your laptop on the way home to check on a project that you just received a text message about. Simply, login via your iOS or Android phone and review the project data. From the main screen access your projects, starred projects, Active projects, those that are critical, and more.",
    infoDetails: [
      {
        title: "Industry",
        description: "Business Management",
      },
      {
        title: "Services",
        description: "Application",
      },
      {
        title: "Country",
        description: "Canada",
      },
      {
        title: "Tech Stacks",
        description: ["Flutter,", " Bloc with CLEAN code structure"],
      },
    ],
    aboutUs:
      "Supporting your organization's security policy you can login by either SSO or basic authentication. Accessing your Clarity information is as simple as a screen tap or finger swipe.",
    aboutUs2:
      "Business never stops and neither do active projects. As you know, situations occur at the most inopportune moments, and likely you do not have access to your computer.",
    moreAbout:
      "Clarity Go provides a simple, intuitive, and familiar interface to your Clarity data via your iOS or Android device.",
    moreAbout2:
      "You can quickly log in and with a few taps and swipes on your device, you are accessing your project. Now, you can take action based on the information literally in your hand.",
    productFeatures: [
      {
        title: "Project Management",
      },
      {
        title: "Security Options",
      },
      {
        title: "Offline Access",
      },
      {
        title: "Real-Time Data Updates",
      },
    ],
    ourView: [
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/ClarityGo/overview/1.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/ClarityGo/overview/2.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/ClarityGo/overview/3.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/ClarityGo/overview/4.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/ClarityGo/overview/5.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/ClarityGo/overview/6.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/ClarityGo/overview/7.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/ClarityGo/overview/8.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/ClarityGo/overview/9.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/ClarityGo/overview/10.png"
            alt=""
          />
        ),
      },
    ],
  },
  {
    projectTitle: "Cryptovestor",
    projecttype: "appdevelopment",
    playstorelink:
      "https://play.google.com/store/apps/details?id=app.stockvest.cryptovestor",
    appstorelink: "https://apps.apple.com/us/app/cryptovestor/id1560885076",
    projectimg1: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/mobileapp/project-images/Cryptovestor/1.png"
        alt=""
      />
    ),
    projectimg2: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/mobileapp/project-images/Cryptovestor/2.png"
        alt=""
      />
    ),
    projectimg3: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/mobileapp/project-images/Cryptovestor/3.png"
        alt=""
      />
    ),
    projectimg4: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/mobileapp/project-images/Cryptovestor/4.png"
        alt=""
      />
    ),
    summaries:
      "Cryptovestor is a revolutionary AI-powered platform designed to assist investors in making informed decisions within the volatile world of cryptocurrencies.",
    summaries2:
      "By harnessing the power of advanced data analysis and machine learning algorithms, Cryptovestor aims to provide users with accurate and real-time insights on which cryptocurrencies to buy and when to sell them.",
    infoDetails: [
      {
        title: "Industry",
        description: "Finance",
      },
      {
        title: "Services",
        description: "Application",
      },
      {
        title: "Country",
        description: "India",
      },
      {
        title: "Tech Stacks",
        description: ["Flutter,", " MVC Pattern"],
      },
    ],
    aboutUs:
      "The platform incorporates cutting-edge technology to monitor a wide range of market indicators, such as price movements, trading volumes, social media sentiment, news sentiment, and technical analysis signals.",
    aboutUs2:
      "These factors are then carefully analyzed to identify potential buying opportunities and optimal exit points for various cryptocurrencies.",
    moreAbout:
      "Please note that while Cryptovestor strives to provide accurate and up-to-date information, the cryptocurrency market is highly unpredictable and subject to rapid fluctuations. As with any investment, users should exercise caution and conduct their research before making any decisions.",
    moreAbout2:
      "Cryptovestor's recommendations are meant to be taken as informational rather than financial advice. Always consult with a licensed financial advisor before making investment decisions.",
    productFeatures: [
      {
        title: "Smart Portfolio Management",
      },
      {
        title: "Real-Time Alerts",
      },
      {
        title: "Data-Driven Insights",
      },
      {
        title: "Educational Resources",
      },
    ],
    ourView: [
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/Cryptovestor/overview/1.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/Cryptovestor/overview/2.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/Cryptovestor/overview/3.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/Cryptovestor/overview/4.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/Cryptovestor/overview/5.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/Cryptovestor/overview/6.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/Cryptovestor/overview/7.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/Cryptovestor/overview/8.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/Cryptovestor/overview/9.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/Cryptovestor/overview/5.png"
            alt=""
          />
        ),
      },
    ],
  },
  {
    projectTitle: "Studentink",
    projecttype: "appdevelopment",
    playstorelink:
      "https://play.google.com/store/apps/details?id=com.msguru.octopod",
    appstorelink: "https://apps.apple.com/in/app/octopod/id1494584140",
    projectimg1: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/mobileapp/project-images/Studentink/1.png"
        alt=""
      />
    ),
    projectimg2: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/mobileapp/project-images/Studentink/2.png"
        alt=""
      />
    ),
    projectimg3: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/mobileapp/project-images/Studentink/3.png"
        alt=""
      />
    ),
    projectimg4: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/mobileapp/project-images/Studentink/4.png"
        alt=""
      />
    ),
    summaries:
      "The connected community of education is a web platform that connects learners, educators, payors and administrators.",
    summaries2:
      "This platform enables students to build their profile for further education, share their achievements and react to those of their colleagues, follow some of the best educators and universities.",
    infoDetails: [
      {
        title: "Industry",
        description: "Education",
      },
      {
        title: "Services",
        description: "Application",
      },
      {
        title: "Country",
        description: "India",
      },
      {
        title: "Tech Stacks",
        description: ["Flutter,", " MVC Pattern"],
      },
    ],
    aboutUs:
      "Students can create personalized profiles that showcase their academic achievements, interests, and extracurricular activities. These profiles act as a digital portfolio, helping learners to present themselves effectively to potential educational institutions or employers.",
    aboutUs2:
      "Students can share their academic accomplishments, awards, and milestones on their profiles. The platform encourages peers to react and engage positively, creating a supportive and motivational atmosphere.",
    moreAbout:
      "Studentink provides a social networking aspect where learners can connect with their colleagues, follow each other's progress, and offer support or encouragement.",
    moreAbout2:
      "Educators, including teachers, professors, and instructors, can build robust professional profiles on Studentink. They can showcase their qualifications, teaching style, and areas of expertise, which can attract a larger following of learners seeking quality education.",
    productFeatures: [
      {
        title: "Learner Profiles",
      },
      {
        title: "Achievement Sharing",
      },
      {
        title: "College and School Engagement",
      },
      {
        title: "Student Acquisition",
      },
    ],
    ourView: [
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/Studentink/overview/1.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/Studentink/overview/2.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/Studentink/overview/3.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/Studentink/overview/4.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/Studentink/overview/5.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/Studentink/overview/6.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/Studentink/overview/7.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/Studentink/overview/8.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/Studentink/overview/9.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-[600px] object-contain my-filterDrpShadow"
            src="/mobileapp/project-images/Studentink/overview/5.png"
            alt=""
          />
        ),
      },
    ],
  },

  // Web development

  {
    projectTitle: "Creditlinks",
    projecttype: "webdevelopment",
    projectlink: "https://www.creditlinks.in/",
    projectimg1: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/Creditlinks/1.png"
        alt=""
      />
    ),
    projectimg2: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/Creditlinks/2.png"
        alt=""
      />
    ),
    projectimg3: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/Creditlinks/3.png"
        alt=""
      />
    ),
    projectimg4: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/Creditlinks/4.png"
        alt=""
      />
    ),
    infoDetails: [
      {
        title: "Industry",
        description: "Finance",
      },
      {
        title: "Services",
        description: "Web Development",
      },
      {
        title: "Country",
        description: "India",
      },
      {
        title: "Tech Stacks",
        description: ["React Js,", "Laravel"],
      },
    ],
    summaries:
      "For lenders, Creditlinks offers a unique opportunity to access a diverse pool of creditworthy applicants. The platform streamlines the lending process, enabling lenders to make informed decisions based on verified financial information. This reduces the risk associated with lending and fosters a more inclusive lending environment.",
    challenges:
      "One critical functionality for this project is setting up a white-label versions site for our merchants.  where we have to set up multiple sub-domains for each merchant. in this type of site content and functionality are the same as our main site but in the UI the logo, fonts, and colors theme are based on the merchant's site.",
    solution:
      "CreditLinks is a financial marketplace helping to connect Indians around the country with financial solutions such as loans, insurance, and exclusive shopping deals. Complete an easy online application to see your results.",
    solution2:
      "CreditLinks is a plug-and-play offering to organizations that have customers requiring loans. ​ We offer a suite of loan products from a curated set of leading lenders. This unique, innovative, first-in-India proposition leverages technology to offer speed and accuracy in intelligently matching borrowers to lenders.",
    productFeatures: [
      {
        title: "Fully Contactless KYC",
      },
      {
        title: "Completely Digital Process",
      },
      {
        title: "Quick & Easy Disbursement",
      },
      {
        title: "24/7 Customer Support",
      },
    ],
    ourView: [
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/Creditlinks/overview/1.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/Creditlinks/overview/2.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/Creditlinks/overview/3.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/Creditlinks/overview/4.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/Creditlinks/overview/5.png"
            alt=""
          />
        ),
      },
    ],
  },
  {
    projectTitle: "Data Poem",
    projecttype: "webdevelopment",
    projectlink: "https://green.datapoem.ai/",
    projectimg1: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/DataPoem/1.png"
        alt=""
      />
    ),
    projectimg2: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/DataPoem/2.png"
        alt=""
      />
    ),
    projectimg3: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/DataPoem/3.png"
        alt=""
      />
    ),
    projectimg4: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/DataPoem/4.png"
        alt=""
      />
    ),
    infoDetails: [
      {
        title: "Industry",
        description: "Marketing",
      },
      {
        title: "Services",
        description: "Web Development",
      },
      {
        title: "Country",
        description: "India",
      },
      {
        title: "Tech Stacks",
        description: ["Vue Js,", "Python"],
      },
    ],
    summaries:
      "Data Poem collaborators work with various datasets, ranging from scientific research to social trends, and transform the raw data into meaningful and evocative verses. They use language, metaphors, and poetic devices to craft narratives that highlight the patterns, trends, and stories hidden within the data.",
    challenges:
      "The team faces the task of continuously collating and refreshing data from over 190 APIs, covering a wide spectrum of Paid, Owned, and Earned Media. Additionally, we incorporate external factors, including competitor data, to ensure comprehensive insights.",
    challenges2:
      "The primary challenge lies in streamlining the onboarding and data refresh processes to maintain real-time, up-to-date information.",
    challenges3:
      "Handle multiple sources within organizations as well as external sources for collated, organized, cleaned up, formatted.",
    solution:
      "DataPOEM is a comprehensive through-the-line planning platform (SaaS) for marketing and allied domains. It is unique in its real-time learning and optimization processes. Our proprietary deep-learning AI engine has been developed by a collective of AI experts, marketers, media planners, and Martech specialists.",
    solution2:
      "For marketers who are driven by imagination but seldom have access to real-time insights, it is a prized weapon to impact today’s interconnected consumers. And save precious marketing dollars too.",
    productFeatures: [
      {
        title: "Pre-trained Models",
      },
      {
        title: "Cookieless",
      },
      {
        title: "Comprehensive Attribution",
      },
      {
        title: "Plug & Play",
      },
    ],
    ourView: [
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/DataPoem/overview/1.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/DataPoem/overview/2.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/DataPoem/overview/3.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/DataPoem/overview/4.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/DataPoem/overview/5.png"
            alt=""
          />
        ),
      },
    ],
  },
  {
    projectTitle: "Geometra",
    projecttype: "webdevelopment",
    projectlink: "https://dev.geometra.rukkor.io/login",
    projectimg1: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/Geometra/1.png"
        alt=""
      />
    ),
    projectimg2: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/Geometra/2.png"
        alt=""
      />
    ),
    projectimg3: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/Geometra/3.png"
        alt=""
      />
    ),
    projectimg4: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/Geometra/4.png"
        alt=""
      />
    ),
    infoDetails: [
      {
        title: "Industry",
        description: "Architecture",
      },
      {
        title: "Services",
        description: "Web Development",
      },
      {
        title: "Country",
        description: "Sweden",
      },
      {
        title: "Tech Stacks",
        description: ["React Js,", "Node Js"],
      },
    ],
    summaries:
      "Geometra offers a fully customizable cost estimation framework. This means users can input data from various sources, including 2D and 3D models, to create detailed and accurate cost estimates for construction projects.",
    challenges:
      "For every project you create, you get a project-specific Drive where you can store all the documentation, blueprints, and IFC models you need.",
    challenges2:
      "Upload your files to your project, and you can start making notes, setting workflow status, and creating quantity take-offs and estimates from them in minutes. All files are automatically shared with everyone in your team who have access to the project.",
    challenges3:
      "Integration of Apryse Implementation Bimer for Rendering IFC file, 3D model Realtime Data Updation.",
    solution:
      "Takeoff for 2D and 3D With Geometra, you can create fast and accurate takeoffs from 2D/PDF drawings and 3D/BIM models as a base for your tender. With our solution, you can easily combine data from both 2D and 3D into one bill of quantities and either price it in Geometra directly or export the data to your preferred estimation tool.",
    solution2:
      "Cost Estimation Framework The best judge of price and time for your work is you. Perhaps you already have established models and data on how you want to price and optimize work hours in your tenders. In Geometra, you can re-use all of your experience and create your own dataset with the price and time for your work. Bring your current professional expertise into Geometra and enhance and combine it with Geometras other features.",
    productFeatures: [
      {
        title: "Takeoff for 2D and 3D",
      },
      {
        title: "Cost Estimation Framework",
      },
      {
        title: "Track project progress",
      },
      {
        title: "Store project documentation",
      },
    ],
    ourView: [
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/Geometra/overview/1.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/Geometra/overview/2.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/Geometra/overview/3.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/Geometra/overview/4.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/Geometra/overview/5.png"
            alt=""
          />
        ),
      },
    ],
  },
  {
    projectTitle: "Golden Suisse",
    projecttype: "webdevelopment",
    projectlink: "https://www.goldensuisse.com/",
    projectimg1: (
      <img
        className="w-full h-full object-cover product-images-animation"
        src="/web-development/project-images/GoldenSuisse/1.png"
        alt=""
      />
    ),
    projectimg2: (
      <img
        className="w-full h-full object-cover product-images-animation"
        src="/web-development/project-images/GoldenSuisse/2.png"
        alt=""
      />
    ),
    projectimg3: (
      <img
        className="w-full h-full object-cover product-images-animation"
        src="/web-development/project-images/GoldenSuisse/3.png"
        alt=""
      />
    ),
    projectimg4: (
      <img
        className="w-full h-full object-cover product-images-animation"
        src="/web-development/project-images/GoldenSuisse/4.png"
        alt=""
      />
    ),
    infoDetails: [
      {
        title: "Industry",
        description: "Finance",
      },
      {
        title: "Services",
        description: "Web Development",
      },
      {
        title: "Country",
        description: "Switzerland",
      },
      {
        title: "Tech Stacks",
        description: ["PHP"],
      },
    ],
    summaries:
      "Golden Suisse strives for excellence, by constantly raising their standards and working with the best people and companies in the world, and solely offering ethical gold and silver.",
    challenges:
      "We have faced challenges to develop a Profit/Loss module. For eg., How can we find historic and potential profit/loss to calculate Profit/Loss.",
    challenges2: "Integrate Profit/Loss module with Backend.",
    solution:
      "For calculate Profit/Loss we have made some formulas for it. For getting correct values of Profit/Loss with Percentage we have to did calculation one by one step using own formula. And finally we are got correct value of our profit/loss and also how much percent (%) profit/loss have we got, we are getting value based on our formula.",
    solution2:
      "We have successfully intigrated Profit/Loss in each and every Golden Suisse modules like Buy Coins/Bars, Sell Coins/Bars, Exchange Coins/Bars and Transfer Coins/Bars. (Combine profit/loss api with main backend)",
    productFeatures: [
      {
        title: "Pay International Bills",
      },
      {
        title: "Transfer Gold Instantly 24*7",
      },
      {
        title: "Personal Advisor",
      },
      {
        title: "Easy to Transfer Money",
      },
    ],
    ourView: [
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/GoldenSuisse/overview/1.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/GoldenSuisse/overview/2.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/GoldenSuisse/overview/3.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/GoldenSuisse/overview/4.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/GoldenSuisse/overview/5.png"
            alt=""
          />
        ),
      },
    ],
  },
  {
    projectTitle: "PVA Deals",
    projecttype: "webdevelopment",
    projectlink: "https://app.pvadeals.com/",
    projectimg1: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/PVADeals/1.png"
        alt=""
      />
    ),
    projectimg2: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/PVADeals/2.png"
        alt=""
      />
    ),
    projectimg3: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/PVADeals/3.png"
        alt=""
      />
    ),
    projectimg4: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/PVADeals/4.png"
        alt=""
      />
    ),
    infoDetails: [
      {
        title: "Industry",
        description: "SaaS Product",
      },
      {
        title: "Services",
        description: "Web Development",
      },
      {
        title: "Country",
        description: "USA",
      },
      {
        title: "Tech Stacks",
        description: ["React Js,", "Node Js"],
      },
    ],
    summaries:
      "The app allows users to purchase virtual phone numbers for specific websites. These phone numbers are not real physical numbers but act as proxies, receiving SMS verification codes and messages on behalf of the user.",
    challenges:
      "Managing a cron job for short-term number rentals. When a number is purchased for short-term use, it is only available for 5 minutes.",
    challenges2:
      "As a result, a cron job needed to be implemented to constantly check every second whether the number had expired or not.",
    challenges3:
      "This ensured smooth and timely handling of short-term rentals, preventing any disruptions in service.",
    solution:
      "We implemented a robust solution that ensures smooth and timely handling of rental numbers. When a user purchases a number for short-term use, it is only available for 5 minutes.",
    solution2:
      "Our solution involves the implementation of a cron job that runs every second to check whether a rented number has expired. This frequent checking allows us to promptly release the number back to the pool of available numbers once its short-term rental period is over. By constantly monitoring the expiration status of rented numbers, we can prevent any disruptions in service and ensure a seamless experience for our users.",
    solution3:
      "Our priority is to provide reliable and efficient SMS verification services, and the implementation of the cron job is a key part of achieving this goal. By constantly monitoring and managing short-term number rentals, we maintain a high level of availability and responsiveness for our users, ensuring their verification needs are met seamlessly.",
    productFeatures: [
      {
        title: "Cryptocurrency Accept",
      },
      {
        title: "Short & Long Time Rental",
      },
      {
        title: "Get Random Phone Numbers",
      },
      {
        title: "24*7 Support",
      },
    ],
    ourView: [
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/PVADeals/overview/1.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/PVADeals/overview/2.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/PVADeals/overview/3.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/PVADeals/overview/4.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/PVADeals/overview/5.png"
            alt=""
          />
        ),
      },
    ],
  },
  {
    projectTitle: "USP",
    projecttype: "webdevelopment",
    projectlink: "https://usp.ai/",
    projectimg1: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/USP/1.png"
        alt=""
      />
    ),
    projectimg2: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/USP/2.png"
        alt=""
      />
    ),
    projectimg3: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/USP/3.png"
        alt=""
      />
    ),
    projectimg4: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/USP/4.png"
        alt=""
      />
    ),
    infoDetails: [
      {
        title: "Industry",
        description: "SaaS Product",
      },
      {
        title: "Services",
        description: "Web Development",
      },
      {
        title: "Country",
        description: "United Kingdom",
      },
      {
        title: "Tech Stacks",
        description: ["Next Js,", "Nest Js"],
      },
    ],
    summaries:
      "At USP.ai, we set out to revolutionize how professionals create high-quality images. Existing generative AI tools in the market lacked the versatility and sophistication we needed, so we decided to create something truly groundbreaking.",
    challenges:
      "Generate appropriate and high-quality images based on user-provided prompts.",
    challenges2: "Collaborating as a team on such a complex task is required.",
    solution:
      "At USP.ai, we have tackled the challenge of generating appropriate and high-quality images based on user-provided prompts by developing our own cutting-edge models and prompt generators.",
    solution2:
      "We pushed the boundaries of generative AI and harnessed the power of state-of-the-art models such as Stable Diffusion v2.1, Midjourney 4, Midjourney paper-cut, and Anything v3, along with proprietary models.",
    solution3:
      "This approach enables us to deliver exceptional image quality and keep pace with the rapid evolution of technology. Our dedication to innovation has led us to revolutionize the way professionals create stunning visuals.",
    productFeatures: [
      {
        title: "One time purchase",
      },
      {
        title: "Recipes page",
      },
      {
        title: "Showcase and search",
      },
      {
        title: "Latest AI models",
      },
    ],
    ourView: [
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/USP/overview/1.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/USP/overview/2.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/USP/overview/3.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/USP/overview/4.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/USP/overview/5.png"
            alt=""
          />
        ),
      },
    ],
  },
  {
    projectTitle: "Volume Network",
    projecttype: "webdevelopment",
    projectlink: "https://www.volumenetwork.com/",
    projectimg1: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/VolumeNetwork/1.png"
        alt=""
      />
    ),
    projectimg2: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/VolumeNetwork/2.png"
        alt=""
      />
    ),
    projectimg3: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/VolumeNetwork/3.png"
        alt=""
      />
    ),
    projectimg4: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/VolumeNetwork/4.png"
        alt=""
      />
    ),
    infoDetails: [
      {
        title: "Industry",
        description: "SaaS Product",
      },
      {
        title: "Services",
        description: "Web Development",
      },
      {
        title: "Country",
        description: "UK",
      },
      {
        title: "Tech Stacks",
        description: ["React Js,", "Node Js"],
      },
    ],
    summaries:
      "HubbleTM CMS system makes it fast and easy to change the content you put into the world – and measure its impact.",
    summaries2:
      "Advanced content management features mean you can control any content on any screen within your estate, making it reactive, targeted, and more effective than ever before.",
    challenges:
      "Design and implement a system that allows devices, such as sound bars and TVs, to be controlled through QR code scanning.",
    challenges2:
      "The system should support various device visions, including mini-vision, large-vision-sound bar, large-vision OLED, and vision.",
    challenges3:
      "Each vision should have a specific functionality implementation, and the control mechanism should only work with the respective vision's fixture.",
    challenges4:
      "Ensuring seamless and secure device management and control is crucial to provide a user-friendly and efficient experience for customers using different visions of the devices.",
    solution:
      "The team have designed and implemented an innovative CMS system that empowers users to control and manage their devices seamlessly through QR code scanning.",
    solution2:
      "The system supports various device visions, including mini-vision, large-vision-sound bar, large-vision OLED, and vision. Each vision is equipped with a specific functionality implementation tailored to its unique capabilities.",
    solution3:
      "By leveraging the QR code scanning feature, users can efficiently control their sound bars and TVs with ease and precision, ensuring a user-friendly and efficient experience.",
    solution4:
      "CMS system offers advanced content management features, enabling users to easily update and customise the content displayed on any screen within their estate.",
    solution5:
      "Additionally, The system seamlessly integrates with cameras and sensors to gather data on footfall, age, gender, and mood, providing real-time insights into marketing performance and identifying future opportunities. This data-driven approach empowers users to measure the impact of their content and make informed decisions to enhance customer engagement and overall marketing effectiveness.",
    productFeatures: [
      {
        title: "Easy to Scan",
      },
      {
        title: "Easy to Connect",
      },
      {
        title: "Full-motion Images",
      },
      {
        title: "Highlight Offers and Promotions",
      },
    ],
    ourView: [
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/VolumeNetwork/overview/1.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/VolumeNetwork/overview/2.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/VolumeNetwork/overview/3.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/VolumeNetwork/overview/4.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/VolumeNetwork/overview/5.png"
            alt=""
          />
        ),
      },
    ],
  },
  {
    projectTitle: "Blooprinted",
    projecttype: "webdevelopment",
    projectlink: "https://blooprinted.com/",
    projectimg1: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/Blooprinted/1.png"
        alt=""
      />
    ),
    projectimg2: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/Blooprinted/2.png"
        alt=""
      />
    ),
    projectimg3: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/Blooprinted/3.png"
        alt=""
      />
    ),
    projectimg4: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/Blooprinted/4.png"
        alt=""
      />
    ),
    infoDetails: [
      {
        title: "Industry",
        description: "Task Management",
      },
      {
        title: "Services",
        description: "Web Development",
      },
      {
        title: "Country",
        description: "South Carolina",
      },
      {
        title: "Tech Stacks",
        description: ["Vue Js"],
      },
    ],
    summaries:
      "Whether you want to build a business or master a unique skill, you can find a blooprint for it. Take your life to the next level as experts walk you through specific tasks that will move you closer to accomplishing your desired goal.",
    challenges:
      "Creating a Gantt chart based on milestones was indeed a challenging task, but the project didn't end there. The next obstacle was aligning the Gantt chart with available resources and ensuring proper resource allocation throughout the project.",
    challenges2:
      "Balancing resource constraints and deadlines proved to be a complex puzzle that required careful planning and adjustments.",
    solution:
      "Blooprinted was built from the ground up to help you achieve goals faster—not through endless video lessons or rote memorization.",
    solution2:
      "Our platform is designed around the science of learning to help you get inspired, acquire new skills, and accomplish your goals.",
    productFeatures: [
      {
        title: "Build Your Own Blooprints",
      },
      {
        title: "Publish blooprint to the marketplace",
      },
      {
        title: "Sell Your Blooprint to Thousands",
      },
      {
        title: "Customize Your Blooprint Curriculum",
      },
    ],
    ourView: [
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/Blooprinted/overview/1.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/Blooprinted/overview/2.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/Blooprinted/overview/3.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/Blooprinted/overview/4.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/Blooprinted/overview/5.png"
            alt=""
          />
        ),
      },
    ],
  },
  {
    projectTitle: "Circuit Booking",
    projecttype: "webdevelopment",
    projectlink: "https://www.circuit-booking.com/en",
    projectimg1: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/CircuitBooking/1.png"
        alt=""
      />
    ),
    projectimg2: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/CircuitBooking/2.png"
        alt=""
      />
    ),
    projectimg3: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/CircuitBooking/3.png"
        alt=""
      />
    ),
    projectimg4: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/CircuitBooking/4.png"
        alt=""
      />
    ),
    infoDetails: [
      {
        title: "Industry",
        description: "Track Day App",
      },
      {
        title: "Services",
        description: "Web Development",
      },
      {
        title: "Country",
        description: "Germany",
      },
      {
        title: "Tech Stacks",
        description: ["October cms"],
      },
    ],
    summaries:
      "Circuit Booking is the additional sales channel to your website or direct sales. As an event organizer, you can easily offer available slots for your events on our platform and receive the payment immediately after a customer's booking.",
    challenges:
      "One of the main challenging task was to handle payment with trackdays, to handle the payment subscription wise and then cancel payment and return payment was the challenging task",
    solution:
      "We offer a wide range of track day dates for cars and bikes, driving and riding experience as well as test days for racing teams. Easily bookable, independently from the corresponding organizer.",
    productFeatures: [
      {
        title: "Additional sales channel",
      },
      {
        title: "Full control over your availability",
      },
      {
        title: "Secure online payment",
      },
      {
        title: "Bookable in less than 5 minutes",
      },
    ],
    ourView: [
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/CircuitBooking/overview/1.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/CircuitBooking/overview/2.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/CircuitBooking/overview/3.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/CircuitBooking/overview/4.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/CircuitBooking/overview/5.png"
            alt=""
          />
        ),
      },
    ],
  },
  {
    projectTitle: "Cult Booking",
    projecttype: "webdevelopment",
    projectlink: "https://www.cultbooking.com/en/",
    projectimg1: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/CultBooking/1.png"
        alt=""
      />
    ),
    projectimg2: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/CultBooking/2.png"
        alt=""
      />
    ),
    projectimg3: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/CultBooking/3.png"
        alt=""
      />
    ),
    projectimg4: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/CultBooking/4.png"
        alt=""
      />
    ),
    infoDetails: [
      {
        title: "Industry",
        description: "Hotel Booking Management",
      },
      {
        title: "Services",
        description: "Web Development",
      },
      {
        title: "Country",
        description: "Germany",
      },
      {
        title: "Tech Stacks",
        description: ["Vue Js,", "Laravel"],
      },
    ],
    summaries:
      "CultBooking is a hotel booking engine, or booking button, for hotels, motels, hostels, B&Bs, lodges & guest houses, apartments, villas, or other property and accommodation providers.",
    summaries2:
      "Guests can quickly and seamlessly book rooms directly from this website, social media platforms, or ad campaigns, resulting in loyal guests and maximizing online revenue.",
    challenges:
      "One of the most challenging tasks was to manage the multi-hotel booking engine which custom all the inputs, logos, and everything they can customize as per their requirements. Localization was also the most challenging part because all the labels came from the third-party website.",
    challenges2: "All Data is Dynamic and Without the use of Hardcode ",
    challenges3: "Integration of RoomDb API",
    solution:
      "CultBooking is a hotel booking engine, or booking button, for hotels, motels, hostels, B&Bs, lodges & guest houses, apartments, villas, or other property and accommodation providers. Guests can quickly and seamlessly book rooms directly from this website, social media platforms, or ad campaigns, resulting in loyal guests and maximizing online revenue.",
    solution2:
      "They are providing commission-free bookings by adding a simple code to your website to start receiving reservations. More info you can find on the website.",
    productFeatures: [
      {
        title: "Real-time Availability and Pricing",
      },
      {
        title: "User Reviews and Ratings",
      },
      {
        title: "Secure Booking Process",
      },
      {
        title: "Search Functionality",
      },
    ],
    ourView: [
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/CultBooking/overview/1.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/CultBooking/overview/2.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/CultBooking/overview/3.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/CultBooking/overview/4.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/CultBooking/overview/5.png"
            alt=""
          />
        ),
      },
    ],
  },
  {
    projectTitle: "Edup",
    projecttype: "webdevelopment",
    projectlink: "https://edup.app/",
    projectimg1: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/Edup/1.png"
        alt=""
      />
    ),
    projectimg2: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/Edup/2.png"
        alt=""
      />
    ),
    projectimg3: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/Edup/3.png"
        alt=""
      />
    ),
    projectimg4: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/Edup/4.png"
        alt=""
      />
    ),
    infoDetails: [
      {
        title: "Industry",
        description: "Education",
      },
      {
        title: "Services",
        description: "Web Development",
      },
      {
        title: "Country",
        description: "Singapore",
      },
      {
        title: "Tech Stacks",
        description: ["Vue Js,", "Laravel"],
      },
    ],
    summaries:
      "Practice makes perfect. It’s even more perfect when you can tailor practice questions to your needs. Understand where you went wrong, try again, and make the knowledge your own.",
    summaries2:
      "MOE Teaching Award holder with experience in MOE syllabus working committee and more than 6 years’ experience in top IP school.",
    challenges:
      "Calculating break time awards involves recognizing and rewarding employees based on their adherence to break time policies and regulations. The purpose of such awards is to promote employee well-being, productivity, and compliance with break-time rules.",
    challenges2:
      "To manage break time awards effectively, you must have a reliable system for tracking employee break times. This can be achieved through manual timekeeping, electronic time-tracking systems, or specialized software. Make sure employees record their break times accurately.",
    solution:
      "EdUp is based on the start quiz based on the tutorial watched and also refers to the question bank. It facilitates a break from their time and earns them awards.",
    solution2:
      "Singapore school syllabus for Primary 1 to Primary 6 English, Maths, Science, and Chinese AI for better learning Students can ask for hints if help is needed with a question.",
    solution3:
      "Explanations for all options will also be given upon completion of a question.",
    productFeatures: [
      {
        title: "Break Time Facility",
      },
      {
        title: "AI for better learning",
      },
      {
        title: "Real life rewards",
      },
      {
        title: "Revision Support",
      },
    ],
    ourView: [
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/Edup/overview/1.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/Edup/overview/2.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/Edup/overview/3.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/Edup/overview/4.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/Edup/overview/5.png"
            alt=""
          />
        ),
      },
    ],
  },
  {
    projectTitle: "ilerno",
    projecttype: "webdevelopment",
    projectlink: "https://ilerno.com/",
    projectimg1: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/ilerno/1.png"
        alt=""
      />
    ),
    projectimg2: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/ilerno/2.png"
        alt=""
      />
    ),
    projectimg3: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/ilerno/3.png"
        alt=""
      />
    ),
    projectimg4: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/ilerno/4.png"
        alt=""
      />
    ),
    infoDetails: [
      {
        title: "Industry",
        description: "Education",
      },
      {
        title: "Services",
        description: "Web Development",
      },
      {
        title: "Country",
        description: "UK",
      },
      {
        title: "Tech Stacks",
        description: ["Vue Js,", "Laravel"],
      },
    ],
    summaries:
      "This powerful and user-friendly school management web app aims to bring efficiency, transparency, and convenience to various administrative and educational processes.",
    summaries2:
      "By centralizing data and functionalities, it facilitates effective communication and collaboration among school administrators, teachers, parents, and students, ultimately enhancing the overall educational experience and school management.",
    challenges:
      "One of the main challenging task was to handle to email and email template with permission wise, in which I have to handle all the template dynamic with dynamic fields using that permission user can change the email template as per their requirement and send the mail.",
    challenges2:
      "Another challenging task was handle certificates, in this I have to generate the certificates with portrait and landscape mode with dynamic field with download certificates.",
    solution:
      "Manage your school, staff, and teachers. communicate with parents & students. Get on top of attendance, billing, enrolment, learning plans, and much more – all from one powerful, easy-to-use web app.",
    solution2:
      "The app facilitates seamless communication between parents, students, and teachers.",
    solution3:
      "Parents can receive notifications about their child's progress, upcoming events, and other important announcements. Students can also receive personalized messages and notifications.",
    productFeatures: [
      {
        title: "Communicate Effectively With Your Staff",
      },
      {
        title: "Manage The School Finances",
      },
      {
        title: "Blended Learning Tools For Home Learning",
      },
      {
        title: "Manage Your Courses",
      },
    ],
    ourView: [
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/ilerno/overview/1.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/ilerno/overview/2.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/ilerno/overview/3.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/ilerno/overview/4.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/ilerno/overview/5.png"
            alt=""
          />
        ),
      },
    ],
  },
  {
    projectTitle: "Musika Lessons",
    projecttype: "webdevelopment",
    projectlink: "https://www.musikalessons.com/",
    projectimg1: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/MusikaLessons/1.png"
        alt=""
      />
    ),
    projectimg2: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/MusikaLessons/2.png"
        alt=""
      />
    ),
    projectimg3: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/MusikaLessons/3.png"
        alt=""
      />
    ),
    projectimg4: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/MusikaLessons/4.png"
        alt=""
      />
    ),
    infoDetails: [
      {
        title: "Industry",
        description: "Online Learning Platform",
      },
      {
        title: "Services",
        description: "Web Development",
      },
      {
        title: "Country",
        description: "USA",
      },
      {
        title: "Tech Stacks",
        description: ["Laravel"],
      },
    ],
    summaries:
      "Musika is a resource for individuals seeking qualified independent music teachers who offer lessons for children and adults of all ages and skill levels. Lessons can take place either in a student’s home, in a teacher’s private studio, in any public space the teacher has access to, or online via a video chat service such as Skype. Teachers work individually with their students to create lesson plans they feel will work best for each student’s goals.",
    challenges:
      "Video calling is the most challenging task because it's like a Skype call when we call some teacher or student at the same time system will get the notification with the ring and then they will receive the call and screen share, record the session, and so on.",
    challenges2:
      "The recipient should have the option to accept or reject incoming calls. If the ring is declined or unanswered, appropriate feedback should be provided to the calling user.",
    solution:
      "Musika provides facilities for the teachers to occupy a unique place in music education mentoring students from all walks of life. so Students Learn in the comfort of their own home, at a teacher’s studio, or anywhere that is convenient for them.",
    solution2:
      "So Students Learn in the comfort of their own home, at a teacher’s studio, or anywhere that is convenient for them.",
    productFeatures: [
      {
        title: "Voice Lessons",
      },
      {
        title: "Piano Lessons",
      },
      {
        title: "Guitar Lessons",
      },
      {
        title: "Convenient Music Lessons.",
      },
    ],
    ourView: [
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/MusikaLessons/overview/1.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/MusikaLessons/overview/2.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/MusikaLessons/overview/3.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/MusikaLessons/overview/4.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/MusikaLessons/overview/5.png"
            alt=""
          />
        ),
      },
    ],
  },
  {
    projectTitle: "My Songs",
    projecttype: "webdevelopment",
    projectlink: "https://mysongs2.com/",
    projectimg1: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/MySongs/1.png"
        alt=""
      />
    ),
    projectimg2: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/MySongs/2.png"
        alt=""
      />
    ),
    projectimg3: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/MySongs/3.png"
        alt=""
      />
    ),
    projectimg4: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/MySongs/4.png"
        alt=""
      />
    ),
    infoDetails: [
      {
        title: "Industry",
        description: "Entertainment",
      },
      {
        title: "Services",
        description: "Web Development",
      },
      {
        title: "Country",
        description: "USA",
      },
      {
        title: "Tech Stacks",
        description: ["Laravel"],
      },
    ],
    summaries:
      "The personalized Songs for Children website offer a creative and heartwarming way to celebrate special occasions by gifting customized pieces.",
    summaries2:
      "With the ability to customize songs for various events and the convenience of e-gift cards, the platform makes it easy for users to create memorable and thoughtful gifts for the little ones in their lives.",
    challenges:
      "One functionality is an e-gift card where the team has to manage the balance and share it with friends so they can use it while purchasing new songs.",
    challenges2:
      "After the e-gift card purchase is completed, the website automatically generates a unique code or QR code associated with the e-gift card.",
    challenges3:
      "The website then sends an email to the specified recipient with the e-gift card details, including the code, the sender's name, and the card's balance. The email may also include a personalized message from the sender.",
    solution:
      "The website is for personalized Songs for Children. For birthdays, holidays, or any special occasion! and you can buy a new personalized song, there is also e-gift functionality where you can buy the e-gift card and share it with your friends.",
    solution2:
      "The main highlight of the website is the ability to create personalized songs for children. Users can specify details like the child's name, age, interests, and the occasion for which the song is intended.",
    solution3:
      "Professional musicians and singers then craft the song, incorporating these personalized elements to make it truly unique and special.",
    productFeatures: [
      {
        title: "One Time Payment",
      },
      {
        title: "E-Gift Card",
      },
      {
        title: "Personalized Songs",
      },
      {
        title: "25000+ Songs",
      },
    ],
    ourView: [
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/MySongs/overview/1.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/MySongs/overview/2.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/MySongs/overview/3.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/MySongs/overview/4.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/MySongs/overview/5.png"
            alt=""
          />
        ),
      },
    ],
  },
  {
    projectTitle: "Octopod",
    projecttype: "webdevelopment",
    projectlink: "https://octopod.co.in/",
    projectimg1: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/Octopod/1.png"
        alt=""
      />
    ),
    projectimg2: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/Octopod/2.png"
        alt=""
      />
    ),
    projectimg3: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/Octopod/3.png"
        alt=""
      />
    ),
    projectimg4: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/Octopod/4.png"
        alt=""
      />
    ),
    infoDetails: [
      {
        title: "Industry",
        description: "Education",
      },
      {
        title: "Services",
        description: "Web Development",
      },
      {
        title: "Country",
        description: "India",
      },
      {
        title: "Tech Stacks",
        description: ["Laravel"],
      },
    ],
    summaries:
      "The edtech platform aims to revolutionize digital learning by creating a vibrant networking community that brings students, educators, and academics together.",
    challenges:
      "The biggest challenging tasks was managing cash flow, academy banners, chatting system, manage student's academic and real-time data management.",
    solution:
      "We ain’t just an edtech platform focusing on Digital Learning, but building a synchronized networking community to bridge the gap created between physical and virtual classrooms.",
    solution2:
      "We provide you with an ERP system to help you gain a wholesome experience of analyzing, tracking, and understanding the working of academics, students, and educators. Yet presenting it with a UI/UX like Facebook to keep it engaging and interesting.",
    productFeatures: [
      {
        title: "High Performing ERP System",
      },
      {
        title: "An Educational Networking Platform",
      },
      {
        title: "Indepth Analysis of Children",
      },
      {
        title: "Detailed Online Pedagogy",
      },
    ],
    ourView: [
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/Octopod/overview/1.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/Octopod/overview/2.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/Octopod/overview/3.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/Octopod/overview/4.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/Octopod/overview/5.png"
            alt=""
          />
        ),
      },
    ],
  },
  {
    projectTitle: "Strategy Exe",
    projecttype: "webdevelopment",
    projectlink: "https://www.strategyexe.com/",
    projectimg1: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/StrategyExe/1.png"
        alt=""
      />
    ),
    projectimg2: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/StrategyExe/2.png"
        alt=""
      />
    ),
    projectimg3: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/StrategyExe/3.png"
        alt=""
      />
    ),
    projectimg4: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/StrategyExe/4.png"
        alt=""
      />
    ),
    infoDetails: [
      {
        title: "Industry",
        description: "Task Management Tool",
      },
      {
        title: "Services",
        description: "Web Development",
      },
      {
        title: "Country",
        description: "Singapore",
      },
      {
        title: "Tech Stacks",
        description: ["CakePHP,", "Vue Js"],
      },
    ],
    summaries:
      "StrategyX offers a comprehensive platform that streamlines the process of turning strategic vision into actionable plans and provides the tools needed to monitor and report on the progress effectively.",
    summaries2:
      "This enables businesses to maintain focus, make data-driven decisions, and achieve their strategic objectives efficiently.",
    challenges:
      "At StrategyExe, the team is currently facing a significant data management challenge, dealing with an overwhelming amount of data that requires efficient handling through queues.",
    challenges2:
      "Moreover, we need to implement a functionality to automatically check and update the status of data entries at midnight. To overcome these obstacles, we seek a solution that involves setting up a robust cron job.",
    challenges3: "Drag and Drop functionality is the one main challenges.",
    solution:
      "StrategyX transforms your strategic vision into an execution model with all the checks and balances needed to keep you on track. In one simple dashboard, you capture everything relevant to the critical operation of your business including success factors, risks, tactical projects, and any ideas and problems you’re facing.",
    solution2:
      "Next, that vision is converted into a series of interrelated SMART goals, paying special attention to areas of focus, actions required to achieve your goals, and any recurring tasks you need to monitor. From there, StrategyX gives you a variety of dynamic reports to show you what’s working, what needs attention, and where you’re going off the rails at any given moment in time. You know exactly the most strategic place to focus your attention. Nothing slips through the cracks. The same reporting features used to ensure the daily execution of your strategy are also suitable for reporting to your board and stakeholders.",
    productFeatures: [
      {
        title: "Free Live Support",
      },
      {
        title: "Free Online and Hands-On Training",
      },
      {
        title: "Digital Onboarding",
      },
      {
        title: "Free Migration and Implementation",
      },
    ],
    ourView: [
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/StrategyExe/overview/1.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/StrategyExe/overview/2.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/StrategyExe/overview/3.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/StrategyExe/overview/4.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/StrategyExe/overview/5.png"
            alt=""
          />
        ),
      },
    ],
  },
  {
    projectTitle: "Taskick",
    projecttype: "webdevelopment",
    projectlink: "https://taskick.net/",
    projectimg1: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/Taskick/1.png"
        alt=""
      />
    ),
    projectimg2: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/Taskick/2.png"
        alt=""
      />
    ),
    projectimg3: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/Taskick/3.png"
        alt=""
      />
    ),
    projectimg4: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/Taskick/4.png"
        alt=""
      />
    ),
    infoDetails: [
      {
        title: "Industry",
        description: "Task Management",
      },
      {
        title: "Services",
        description: "Web Development",
      },
      {
        title: "Country",
        description: "USA",
      },
      {
        title: "Tech Stacks",
        description: ["Vuejs"],
      },
    ],
    summaries:
      "Taskick streamlines project management by breaking down tasks into actionable steps, providing clear progress tracking and encouraging collaboration among team members.",
    summaries2:
      "With its focus on productivity and efficiency, Taskick aims to enhance organizational performance and help employees deliver projects on time and with clarity.",
    challenges:
      "Task management was challenging because have to manage Implementing task tracking and progress monitoring, Time management.",
    challenges2:
      "One more challenge is Chat modules and there was a time-tracking app also.",
    solution:
      "Taskick is a one-stop productivity solution that makes you able to manage your projects, assign tasks, monitor your team performance, and provide a high collaboration way to get the best operational efficiency of the organization which the employees' productivity is a key parameter.",
    solution2:
      "Easily add a new project and attach its steps or sub-tasks with a simple click. you can have your actions in the form of a to-do list that checks each one off as it is completed to calculate the project's progress percentage.",
    solution3:
      "Schedule tasks according to your schedule and set the final delivery date for each project or sub-task to receive alerts that help employees deliver on time.",
    productFeatures: [
      {
        title: "Real-time control",
      },
      {
        title: "Tracking Dashboard",
      },
      {
        title: "Attendance Tracking",
      },
      {
        title: "App-usage Monitoring",
      },
    ],
    ourView: [
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/Taskick/overview/1.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/Taskick/overview/2.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/Taskick/overview/3.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/Taskick/overview/4.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/Taskick/overview/5.png"
            alt=""
          />
        ),
      },
    ],
  },
  {
    projectTitle: "Woptics",
    projecttype: "webdevelopment",
    projectlink: "https://woptics.sg/",
    projectimg1: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/Woptics/1.png"
        alt=""
      />
    ),
    projectimg2: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/Woptics/2.png"
        alt=""
      />
    ),
    projectimg3: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/Woptics/3.png"
        alt=""
      />
    ),
    projectimg4: (
      <img
        className="w-full h-full object-cover product-images-animation my-filterDrpShadow"
        src="/web-development/project-images/Woptics/4.png"
        alt=""
      />
    ),
    infoDetails: [
      {
        title: "Industry",
        description: "Task Management",
      },
      {
        title: "Services",
        description: "Web Development",
      },
      {
        title: "Country",
        description: "Singapore",
      },
      {
        title: "Tech Stacks",
        description: ["Vue Js,", "Laravel"],
      },
    ],
    summaries:
      "At W OPTICS, we believe in offering tailored vision care services to cater to the specific needs of customers, making every visit a distinguished and exclusive experience. We are committed to changing the approach to vision care, by optical solutions and products at the forefront of the industry.",
    aboutUs:
      "The vision care and optical industry can be highly competitive, with various established players and new entrants.",
    aboutUs2:
      "Moving from in-store services to a digital platform requires a seamless transition and adaptation to the digital ecosystem. Training staff and ensuring the app's smooth functioning can be a significant challenge.",
    moreAbout:
      "W Optics, Singapore's trusted vision care and optical chain, is now LIVE and available on App Store. Committed to being the professional leader at the forefront of the industry and the go-to specialist for eye care with an aging population, the ALL-NEW W Optics app allows our tailored vision care services, optical solutions, and products to be more accessible and convenient to customers like you.",
    moreAbout2:
      "Through the new app, you can now purchase optical products at your convenience and have them delivered to your doorstep. Be pampered via an in-app shopping experience with tailored promotions found in our new e-wallet function. Booking vision care services with our professional opticians for both you and your family is now a touch away with our digitalized booking system.",
    productFeatures: [
      {
        title: "Highly Experienced Optometrists",
      },
      {
        title: "Dedicated Kid's Corner",
      },
      {
        title: "Advanced Medical Equipments",
      },
      {
        title: "Vision Therapy",
      },
    ],
    ourView: [
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/Woptics/overview/1.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/Woptics/overview/2.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/Woptics/overview/3.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/Woptics/overview/4.png"
            alt=""
          />
        ),
      },
      {
        viewImages: (
          <img
            className="w-full max-h-96 object-contain my-filterDrpShadow py-5 px-2"
            src="/web-development/project-images/Woptics/overview/5.png"
            alt=""
          />
        ),
      },
    ],
  },
];

export default ProjectData;
